import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import React from "react";
import { HeaderGroup, TableSortByToggleProps } from "react-table";

interface Props {
  headerGroup: HeaderGroup<object>;
}
const SortByToggle: React.FC<Props> = ({ headerGroup }) => {
  const { isSorted, isSortedDesc } = headerGroup;

  function SortArrow(props: Partial<TableSortByToggleProps>) {
    return isSorted ? setArrowDirection(props) : null;
  }

  function setArrowDirection(props: Partial<TableSortByToggleProps>) {
    return isSortedDesc ? <KeyboardArrowDown {...props} /> : <KeyboardArrowUp {...props} />;
  }

  return (
    <>
      <Box {...headerGroup.getSortByToggleProps()}>
        {headerGroup.render("Header")}
      </Box>
      <SortArrow {...headerGroup.getSortByToggleProps()} />
    </>
  );
};
export default SortByToggle;
