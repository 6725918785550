import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'store/api';
import { ClientContact } from 'store/models/ClientContact';
import { byIds } from 'store/sliceUtils';

export type ClientContactsState = {
  clientContacts: {[_id: string]: ClientContact};
  clientContactsLoading: boolean;
};

const initialState: ClientContactsState= {
  clientContacts: {},
  clientContactsLoading: false,
}

const clientContactSlice = createSlice({
    name: 'clientContact',
    initialState,
    reducers: {
        setClientContacts(state, action: PayloadAction<ClientContact[]>) {
            state.clientContacts = byIds(action.payload)
        },
        setOneClientContact(state, action: PayloadAction<ClientContact>) {
            state.clientContacts[`${action.payload._id}`] = action.payload
        },
        setClientContactsLoading(state, action: PayloadAction<boolean>) {
            state.clientContactsLoading = action.payload
        },    
    }
})

export const fetchClientContacts = () => {
    return (dispatch: Function) => {
      api.clientContacts.getByQuery({},{
        onData: (data: ClientContact[]) => {
          dispatch(setClientContacts(data));
        },
        onComplete: () => dispatch(setClientContactsLoading(false)),
      });
    };
  };



export const {
    setClientContacts,
    setOneClientContact,
    setClientContactsLoading,
} = clientContactSlice.actions;

export default clientContactSlice.reducer;