import React from "react";
import { Hooks } from "react-table";
import RowCheckbox from "components/table/RowCheckbox";
import { IconButton } from "@mui/material";

const useRowSelection = (enableRowSelection: boolean, enableSingleRowSelection: boolean) => {
  return (hooks: Hooks<object>) => {
    enableRowSelection &&
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IconButton size="small" disabled={enableSingleRowSelection}>
              <RowCheckbox {...getToggleAllRowsSelectedProps()} />
            </IconButton>
          ),
          minWidth: 48,
          Cell: ({ row, toggleAllRowsSelected, setAllFilters, state }) => {
            const unselectRows = async () => {
              const previousFilters = state.filters
              await new Promise((resolve) => {
                setAllFilters([]);
                resolve("");
              });
              toggleAllRowsSelected(false)
              await new Promise((resolve) => {
                setAllFilters(previousFilters);
                resolve("");
              })
            }

            return (
              <IconButton size="small">
                <RowCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onChange={async () => {
                    enableSingleRowSelection && await unselectRows();
                    row.toggleRowSelected();
                  }}
                />
              </IconButton>
            );
          },
          align: "center",
          width: 2,
        },
        ...columns,
      ]);
  };
};

export default useRowSelection;
