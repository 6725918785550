import { StyledComponent } from "@emotion/styled";
import React from "react";
import { Row } from "react-table";

interface Props {
  row: Row<any>;
  rowSubComponent: ((row: Row<any>) => JSX.Element) | undefined;
  component: StyledComponent<{}>;
}
const RowExpansion: React.FC<Props> = ({
  row,
  rowSubComponent,
  component: TableRow,
}) => {
  return row.isExpanded ? (
    <TableRow {...row.getRowProps()}>
      <div>{rowSubComponent && rowSubComponent(row)}</div>
    </TableRow>
  ) : null;
};
export default RowExpansion;
