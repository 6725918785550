import React, { useEffect } from "react";
import { FilterProps, Row } from "react-table";
import { isBefore, isAfter, isEqual, endOfDay, startOfDay } from "date-fns";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateRangeFilter = <T extends Record<string, unknown>>({
  column,
}: FilterProps<T>) => {
  const { filterValue, setFilter } = column;
  const filterId = "date-range-filter";

  useEffect(function scrollFilterIntoView(){
    document.getElementById(filterId)?.scrollIntoView();
  },[])

  return (
    <Card
      sx={{
        padding: 1,
        borderRadius: 1,
        zIndex: 10,
        width: 360,
      }}
      id={filterId}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
      >
        <Grid item sm={6}>
          <Box>
            <DatePicker
              value={(filterValue && filterValue[0]) || null}
              onChange={(newDate) => setFilter((old = []) => [startOfDay(newDate), old[1]])}
              label="From"
              renderInput={(params) => <TextField {...params} size="small" />}
              disableMaskedInput
              inputFormat="MM-dd-yyyy"
            />
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box>
            <DatePicker
              value={(filterValue && filterValue[1]) || null}
              onChange={(newDate) =>
                setFilter((old = []) => [old[0] || null, endOfDay(newDate)])
              }
              label="To"
              renderInput={(params) => <TextField {...params} size="small" />}
              disableMaskedInput
              inputFormat="MM-dd-yyyy"
            />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Button
            onClick={() => setFilter(undefined)}
            variant="outlined"
            fullWidth
            size="small"
            disabled={!filterValue}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export function filterByDateRange<T extends Object>(
  rows: Array<Row<T>>,
  colunmIds: Array<string>,
  filterValue: [Date, Date]
) {
  return rows.filter((row) => {
    const rowValue = new Date(row.values[colunmIds[0]]);
    const minValue = filterValue[0];
    const maxValue = filterValue[1];
    if (bothDatesSupplied()) {
      return (
        (isAfter(rowValue, minValue) && isBefore(rowValue, maxValue)) ||
        isEqual(rowValue, minValue) ||
        isEqual(rowValue, maxValue)
      );
    } else {
      return (
        isAfter(rowValue, minValue) ||
        isBefore(rowValue, maxValue) ||
        isEqual(rowValue, minValue) ||
        isEqual(rowValue, maxValue)
      );
    }

    function bothDatesSupplied() {
      return filterValue[0] && filterValue[1];
    }
  });
}

export default DateRangeFilter;
