import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EventNote from "@mui/icons-material/EventNote";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import DeleteForever from "@mui/icons-material/DeleteForever";
import AssignmentReturned from "@mui/icons-material/AssignmentReturned";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Movement, { initMovement, AssetMovement, MovementStatus, determineDefaultDateOfMove, MovementType } from "store/models/Movement";
import api from "store/api";
import { selectLoggedInUser, simpleGlobalMessage } from "store/slices/systemSlice";
import { useSelector } from "react-redux";
import { determineDefaultMovementStatus } from "utils/util";
import {
  selectCurrentContract,
  setContract,
} from "store/slices/contractSlice";
import usePermissions, { ActionType, FeatureType, UIActionType } from "hooks/usePermissions";
import ConfirmationModal from "components/ConfirmationModal";
import { selectAssets, setOneAsset } from "store/slices/assetSlice";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import AssetMovementUI from "./AssetMovement";
import DisablePropagation from "components/table/cell-contents/DisablePropagation";
import { AddAssetModalMode, AssetTableFilters } from "components/modals/AddAssetModal";
import { useAppDispatch } from 'store/store';
import UserData from "store/models/UserData";
import { moveableAssetStatuses } from "store/api/asset";
import { contractAssetMoveableStatuses } from "store/api/contract";
import { inactiveContractStatuses } from "store/models/Contract";
import { assetOnActiveContractCanBeRemoved } from "features/contracts/contractUtils";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { selectYards } from "store/slices/optionSlice";

interface Props {
  assetDeliverable: AssetDeliverable;
  disabled?: boolean;
  setAddAssetMode: (mode: AddAssetModalMode, filters?: GridFilterModel, assetDeliverable?: AssetDeliverable) => void;
  setNewMovements: Dispatch<SetStateAction<Movement[]>>
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;

}

const AssetMovements: React.FC<Props> = ({ disabled = false, assetDeliverable, setAddAssetMode, setNewMovements, changeIsSubmitting }) => {
  const currentContract = useSelector(selectCurrentContract);
  const dispatch = useAppDispatch();
  const [assetMovements, changeAssetMovements] = useState<Movement[]>([]);
  const assets = useSelector(selectAssets);
  const assetId = assetDeliverable.asset
  const asset = assets[assetId]
  const loggedInUser = useSelector(selectLoggedInUser);
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const assetHasAccessories = currentContract?.accessories.some((accessory) => accessory.asset === assetId)
  const yards = useSelector(selectYards)

  useEffect(() => {
    changeAssetMovements(
      currentContract?.movements.filter((move) => move.asset === assetId) || []
    );
  }, [currentContract]);

  const handleScheduleMovement = () => {
    const pendingMovement = assetMovements.find((movement) => [MovementStatus.scheduled, MovementStatus.dispatched].includes(movement.status));
    const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    const userCanCreateMovements = checkMovementPermissions(ActionType.CREATE, UIActionType.ONCLICK);

    if(!userCanCreateMovements || !userCanEditAssets || !currentContract || disabled){
      return;
    }

    const defaultMovementStatus = determineDefaultMovementStatus(currentContract);

    setNewMovements([
      initMovement({
        asset: assetId,
        dateOfMove: determineDefaultDateOfMove(defaultMovementStatus, assetDeliverable, currentContract),
        type: pendingMovement ? MovementType.relocate : defaultMovementStatus,
        status: MovementStatus.scheduled,
        contract: currentContract?._id,
        createdBy: (loggedInUser as UserData)._id,
        dateCompleted: null,
      } as AssetMovement),
    ])
  };

  const handleMarkDelivered = () => {
    dispatch(simpleGlobalMessage("In order to mark an asset delivered, create an OUT movement and mark it as COMPLETED"))
  }

  const handleCheckIn = () => {
    dispatch(simpleGlobalMessage("In order to check in an asset, create an IN movement and mark it as COMPLETED"))
  }

  const handleAssetSwap = () => {
    if (assetHasAccessories){
      dispatch(simpleGlobalMessage("Asset has Accessories associated with it"));
      return;
    }

    setAddAssetMode(
      "swap",
      {
        items: [
          {
            id: "status",
            field: "status",
            operator: "in-status",
            value: ["AVAILABLE"],
          },
          {
            id: "yard",
            field: "yard",
            operator: "in-yard",
            value: asset?.yard ? [yards[asset?.yard].name] : [],
          },
          {
            id: "sizeCode",
            field: "sizeCode",
            operator: "in-sizeCode",
            value: asset?.sizeCode ? [asset?.sizeCode] : [],
          },
        ],
      },
      assetDeliverable
    );
  }

  const handleAssetRemove = () => {
    if (assetHasAccessories){
      dispatch(simpleGlobalMessage("Asset has Accessories associated with it"));
      return;
    }
    if (isLastAssetInContract()) {
      dispatch(
        simpleGlobalMessage(
          "There must always be at least one asset in a contract"
        )
      );
      return;
    }

    
    if (currentContract && asset) {
      changeIsSubmitting(true)
      api.contracts.removeAsset(currentContract?._id, asset._id, {
        onData: ({ removedAsset, contract }) => {
          dispatch(setOneAsset(removedAsset));
          dispatch(setContract(contract));
        },
        onComplete: () => changeIsSubmitting(false),
        onError: () => changeIsSubmitting(false)
      });
    }

    function isLastAssetInContract() {
      return currentContract && currentContract.assetDeliverables?.length <= 1
        ? true
        : false;
    }
  }


  const additionalActions = () => {
    if (!currentContract) return;
    switch(true) {
      case ["AWAITING APPROVAL", "AWAITING CONFIRMATION"].includes(currentContract.status) || assetOnActiveContractCanBeRemoved(currentContract, asset):
        return (
          <>
            <Tooltip title="Swap Asset">
              <span>
                <IconButton
                  onClick={handleAssetSwap}
                  disabled={!userCanEditContracts}
                >
                  <SwapHoriz />
                </IconButton>
              </span>
            </Tooltip>
            <ConfirmationModal
              message={`Are you sure you want to remove asset Serial #${asset.serialNumber}?`}
              handleConfirmation={handleAssetRemove}
              disabled={!userCanEditContracts}
              sx={{ display: "inline" }}
            >
              <Tooltip title="Remove Asset">
                <span>
                  <IconButton disabled={!userCanEditContracts}>
                    <DeleteForever />
                  </IconButton>
                </span>
              </Tooltip>
            </ConfirmationModal>
          </>
        );
      case asset.status === "SOLD PENDING DELIVERY":
        return (
          <Tooltip title="Mark Delivered">
            <span>
              <IconButton onClick={handleMarkDelivered}>
                <CheckCircleOutline />
              </IconButton>
            </span>
          </Tooltip>
        )
      case asset.status === "ON LEASE":
        return (
          <Tooltip title="Check In">
            <span>
              <IconButton onClick={handleCheckIn}>
                <AssignmentReturned />
              </IconButton>
            </span>
          </Tooltip>
        )
    }
  }

  const movementIsEditable = (movement: Movement) => {
    const nonRelocateMovements = currentContract?.movements.filter(movement => movement.asset === asset._id && movement.type !== MovementType.relocate) || []
    const relocateMovements = currentContract?.movements.filter(movement => movement.asset === asset._id && movement.type === MovementType.relocate) || []
    const isLatestNonRelocate = nonRelocateMovements?.findIndex(move => move._id === movement._id) === nonRelocateMovements.length - 1
    const isLatestRelocate =  relocateMovements?.findIndex(move => move._id === movement._id) === relocateMovements.length - 1
    const contractIsActive =  currentContract && !inactiveContractStatuses.includes(currentContract?.status)
    return isLatestRelocate || (isLatestNonRelocate && contractIsActive)
  }

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        "&.Mui-expanded": { boxShadow: "none" },
        ":before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMore />
          </IconButton>
        }
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 0,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 1, alignItems: "center", display: "flex" }}>
          {assetDeliverable.isActiveOnContract && (
            <DisablePropagation>
              <Tooltip title="Schedule Movement">
                <IconButton
                  onClick={handleScheduleMovement}
                  disabled={
                    disabled ||
                    !currentContract ||
                    !contractAssetMoveableStatuses.includes(
                      currentContract?.status || ""
                    ) ||
                    !moveableAssetStatuses.includes(asset.status)
                  }
                >
                  <EventNote />
                </IconButton>
              </Tooltip>
              {additionalActions()}
            </DisablePropagation>
          )}
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography color="primary">
          Movements ({assetMovements.length})
        </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            columnGap: 2,
            rowGap: 1,
          }}
        >
          {assetMovements.length
            ? assetMovements.map((movement, index) => {
                return (
                  <AssetMovementUI
                    movement={movement}
                    isEditable={movementIsEditable(movement)}
                    disabled={disabled}
                    key={index}
                    changeIsSubmitting={changeIsSubmitting}
                  />
                );
              })
            : "No Movements"}
        </Box>
      </AccordionDetails>
    </Accordion>
  );};
export default AssetMovements;


