import Category from '@mui/icons-material/Category';
import { styled } from '@mui/material/styles';
import React from 'react';
import { HeaderGroup } from 'react-table';
interface Props {
  headerGroup: HeaderGroup<object>;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

const GroupByIcon = styled(Category)({
  marginRight: 4
});

const GroupByToggle: React.FC<Props> = ({ headerGroup, gotoPage }) => {

  const resetPaginator = () => {
    gotoPage(0)
  }

  return (
    headerGroup.canGroupBy ? (
      <span {...headerGroup.getGroupByToggleProps()}>
        {headerGroup.isGrouped ? (
          <GroupByIcon />
        ) : (
          <GroupByIcon
            color="disabled"
            onClick={resetPaginator}
          />
        )}
      </span>
    ) : null
  )
}
export default GroupByToggle;