import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import React from "react";
import { Cell, Row } from "react-table";

interface Props {
  row: Row<object>;
  cell: Cell<object, any>;
}

const CellContent = (props: Props) => {
  const { row, cell } = props;
  const cellContents =  cell.render("Cell");

  const groupedCellContents = () => (
    <>
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
      </span>
      {cell.render("Cell")} ({row.subRows.length})
    </>
  );

  function hasAccessor(){
    return typeof cell.value ==="string"
  }

  return cell.isGrouped
    ? groupedCellContents()
    : cell.isAggregated
    ? <>{cell.render("Aggregated")}</>
    : cell.isPlaceholder
    ? null
    : hasAccessor() ?(
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {cellContents}
      </Box>
    ) : 
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{cellContents}</>
};

export default CellContent;
