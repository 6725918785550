import { Lead, LeadAction, LeadStatus, LeadWithPopulatedContact } from 'store/models/Lead';
import { sendToBackend } from "store/api/util";
import { errorHandler } from "./shared";
import { createMongoId } from 'utils/createMongoId';
import { toMMDDYYYY } from 'utils/util';
import { placeholderAddress } from 'store/api/contract';
import { initClientContact } from 'store/api/clientContact';

const leadApi = {
  getAll: (
    query: {},
    actions?: {
      onData?: (data: LeadWithPopulatedContact[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    },

  ) => {
    sendToBackend<LeadWithPopulatedContact[]>({
      route: `lead/query`,
      body: query,
      method: "POST",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
   createOne: (
    lead: LeadWithPopulatedContact,
    actions?: {
      onData?: (data: LeadWithPopulatedContact) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<LeadWithPopulatedContact>({
      route: `lead/`,
      method: "POST",
      body: lead,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  updateOne: (
    lead: LeadWithPopulatedContact,
    actions?: {
      onData?: (data: LeadWithPopulatedContact) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<LeadWithPopulatedContact>({
      route: `lead/`,
      method: "PATCH",
      body: lead,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  }
};

export default leadApi;

export const initLead = (lead: Partial<LeadWithPopulatedContact>): LeadWithPopulatedContact => {
  return {
    _id: createMongoId(),
    assignedTo: null,
    branch: "",
    clientRef: null,
    clientStr: "",
    closeProbability: 0,
    contact: initClientContact({firstName: ""}),
    country: "USA",
    createdOn: toMMDDYYYY(new Date()),
    estimatedValue: {currency: "USD", amount: 0},
    leadSource: "Google",
    leadSourceId: "",
    actionRecords: [],
    notes: "",
    products: [],
    projectLocation: placeholderAddress,
    projectStart: toMMDDYYYY(new Date()),
    status: LeadStatus.OPEN,
    term: 12,
    type: "Rental",
    attachments: [],
    ...lead
  };
}

export const initAction = (action: Partial<LeadAction>): LeadAction => {
  return {
    _id: createMongoId(),
    actionType: "Call",
    by: "",
    date: toMMDDYYYY(new Date()),
    notes: "",
    complete: false,
    ...action
  }
}
