import DateRangeFilter from "components/table/filters/DateRangeFilter";
import ExcelFilter from "components/table/filters/ExcelFilter";
import TextFilter from "components/table/filters/TextFilter";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { CellProps, Column } from "react-table";
import { IChecklistItem } from "store/models/Inspection";
import { richTextToString } from "utils/formatUtils";
import { toDDMMMYYYY, dateFromMMDDYYYY } from "utils/util";
import ReactTable, { TableActionProps } from 'components/table/ReactTable';
import { Typography } from "@mui/material";
import { WebWOQueryResponse } from "store/services/workOrder";

interface Props {
  checkListItems: IChecklistItem[];
  tableActions?: ({ selectedRows, toggleAllRowsSelected }: TableActionProps) => JSX.Element[];
  onRowClick?: (checkListItem:IChecklistItem) => void;
  generatedWorkOrders: WebWOQueryResponse[]
}

const FailedListItemTable: FC<Props> = ({
  checkListItems,
  tableActions,
  onRowClick,
  generatedWorkOrders
}) => {

  const generatedWorkOrderHash = generatedWorkOrders.reduce(
    (hash: Record<string, WebWOQueryResponse>, workOrder) => {
      hash[workOrder._id] = workOrder;
      return hash;
    }, {})


  const isInvalid = (checkListItem: IChecklistItem) => {
    return !Boolean(
      checkListItem.estimatedMaterials.amount >= 0 &&
      checkListItem.estimatedHours >= 0 &&
      checkListItem.dueDate &&
      checkListItem.title &&
      checkListItem.workOrderCategory &&
      checkListItem.workOrderSubcategory && 
      checkListItem.priority
    )
  }

  const renderTaskName = useCallback(
    ({ row, cell }: PropsWithChildren<CellProps<IChecklistItem, any>>) =>
      isInvalid(row.original) ? (
        <Typography color="error">{cell.value}</Typography>
      ) : (
        cell.value
      ),
    [checkListItems]
  ); 

  const columns: Column<IChecklistItem>[] = useMemo(
    () => [
      {
        Header: "Task Name",
        accessor: "taskName",
        Filter: TextFilter,
        Cell: renderTaskName,
      },
      {
        Header: "Title",
        accessor: "title",
        Filter: TextFilter,
      },
      {
        Header: "WO #",
        accessor: ({ workOrder }) => generatedWorkOrderHash[workOrder || ""]?.number || "",
        Filter: TextFilter,
      },
      {
        Header: "Description",
        accessor: (original) =>
          original.description ? richTextToString(original.description) : "",
        id: "description",
        Filter: TextFilter,
      },
      {
        Header: "Hours",
        accessor: "estimatedHours",
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        Filter: DateRangeFilter,
        filter: "dateRange",
        sortType: "stringDateSort",
        Cell: (value) =>
          value.cell.value && toDDMMMYYYY(dateFromMMDDYYYY(value.cell.value)),
      },
      {
        Header: "Priority",
        accessor: "priority",
        Filter: ExcelFilter,
        filter: "multiSelect",
      },
      {
        Header: "Category",
        accessor: "workOrderCategory",
        Filter: ExcelFilter,
        filter: "multiSelect",
      },
      {
        Header: "Sub Category",
        accessor: "workOrderSubcategory",
        Filter: ExcelFilter,
        filter: "multiSelect",
      },
    ],
    [checkListItems]
  );

  return (
    <ReactTable
      id="check-list-item-table"
      data={checkListItems}
      columns={columns}
      onRowClick={(row) => onRowClick && onRowClick(row.original)}
      tableActions={tableActions}
    />
  )
}

export default FailedListItemTable;
