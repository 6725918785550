import { errorHandler } from "store/api/shared";
import { getQueryStringFromObject, sendToBackend } from "store/api/util";
import { ClientContact } from "store/models/ClientContact"
import { createMongoId } from "utils/createMongoId"


export const clientContactApi = {
  getAll: (
    actions?: {
      onData?: (data: ClientContact[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    },
  ) => {
    sendToBackend<ClientContact[]>({
      route: `clientContact/`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  getByQuery: (
    query: Partial<Record<keyof ClientContact, string | number>>,
    actions?: {
      onData?: (data: ClientContact[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    },
  ) => {
    sendToBackend<ClientContact[]>({
      route: `clientContact/query`,
      body: query,
      method: "POST",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  search: (
    search: string,
    clientId?: string,
    actions?: {
      onData?: (data: ClientContact[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    },
  ) => {
    sendToBackend<ClientContact[]>({
      route: `clientContact/search/?${getQueryStringFromObject({search, clientId})}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  createOne: (
    clientContact: ClientContact,
    actions?: {
      onData?: (data: ClientContact) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<ClientContact>({
      route: `clientContact/`,
      method: "POST",
      body: clientContact,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  updateOne: (
    clientContact: ClientContact,
    actions?: {
      onData?: (data: ClientContact) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<ClientContact>({
      route: `clientContact/`,
      method: "PATCH",
      body: clientContact,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
}


export const initClientContact = (
  clientContact: Partial<ClientContact>
): ClientContact => {
  return {
    _id: createMongoId(),
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    altPhone: "",
    companyRole: "",
    currentClient: "",
    ...clientContact,
  };
};
  