import { Tooltip, IconButton } from '@mui/material';
import Iconify from './Iconify';
import { useAppDispatch } from 'store/store';
import { setGlobalMessage } from 'store/slices/systemSlice';

// ----------------------------------------------------------------------

type Props = {
  value: string;
  tooltipText?: string
  successText?: string
  emptyText?: string
};

export default function CopyClipboard({ value, tooltipText = "Copy", successText = "Copied", emptyText = "Nothing to copy..."}: Props) {

  const dispatch = useAppDispatch();

  const copyToClipboard = (event: React.MouseEvent) => {
    event.stopPropagation();
    var data = [
      new ClipboardItem({
        "text/plain": new Blob([value], { type: "text/plain" }),
      }),
    ];
    navigator.clipboard
      .write(data)
      .then(() => {
        if (value) {
          dispatch(
            setGlobalMessage({
              show: true,
              severity: "success",
              messageText: successText,
              xPosition: "right"
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              show: true,
              severity: "error",
              messageText: emptyText,
            })
          );
        }
      })
      .catch((error) => dispatch(
        setGlobalMessage({
          show: true,
          severity: "error",
          messageText: "Unable to copy data",
        })
      ))
     
  };

  return (
    <Tooltip title={tooltipText}>
      <IconButton onClick={(event) => copyToClipboard(event)}>
        <Iconify icon="eva:copy-fill" width={24} height={24} />
      </IconButton>
    </Tooltip>
  );
}

