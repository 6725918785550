import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

interface Props {
  isLoading: boolean;
}

const TableLoading: React.FC<Props> = ({ isLoading }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1000,
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.5
      }}

    >
      {isLoading ? <CircularProgress color="primary" size={75} /> : null}
    </Box>
  );
};

export default TableLoading;
