import Done from "@mui/icons-material/Done";
import { Box, Button, Chip } from "@mui/material";
import _ from "lodash";
import React, { FC, forwardRef, useState } from "react";
import { ColumnInstance } from "react-table";
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities"

interface Props {
  allColumns: ColumnInstance<object>[];
  optionalColumns: string[];
  hiddenColumns: string[];
  setHiddenColumns: (param: string[]) => void;
  setColumnOrder:  (updater: string[] | ((columnOrder: string[]) => string[])) => void;
  visibleColumns: ColumnInstance<any>[];
  tableId?: string;
}

const ColumnVisibilityToggleChips = ({
  allColumns,
  optionalColumns,
  hiddenColumns,
  setHiddenColumns,
  visibleColumns,
  tableId = "",
  setColumnOrder,
}: Props) => {
  const onlyColumnsWithAdjustableVisibility = (
    column: ColumnInstance<object>
  ) => optionalColumns.includes(column.id);

  const [userDefaultColumns, setUserVisibleColumns] = useState<string[]>(
    getUserVisibleColumns()
  );
  const [activeId, setActiveId] = useState(null);

  const visibleColumnsIds = visibleColumns.map((column) => column.id);
  const selectableColumns = allColumns.filter(onlyColumnsWithAdjustableVisibility)
  function getUserVisibleColumns() {
    return JSON.parse(String(localStorage.getItem(tableId))) || [];
  }

  const setDefaultColumns = () => {
    tableId && localStorage.setItem(tableId, JSON.stringify(visibleColumnsIds));
    setUserVisibleColumns(getUserVisibleColumns());
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setColumnOrder((columns) => {
        const oldIndex = columns.indexOf(active.id);
        const newIndex = columns.indexOf(over.id);
        const newOrder = arrayMove(columns, oldIndex, newIndex);
        localStorage.setItem(`${tableId}-column-order`, JSON.stringify(newOrder))
        setColumnOrder(newOrder)
        return newOrder
      });
    }
    setActiveId(null);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {activationConstraint: {delay: 500, tolerance: 10}} ),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event: any) {
    const { active } = event;
    setActiveId(active.id);
  }

  const selectedColumn = selectableColumns.find(column => column.id === activeId)

  return (
    <Box>
      <Box sx={{display: "flex"}}>
        Hidden Columns{" "}
        <Button onClick={() => setHiddenColumns(hiddenColumns)} size="small">
          Select All
        </Button>
        {tableId && (
          <Button
            onClick={setDefaultColumns}
            size="small"
            disabled={_.isEqual(userDefaultColumns, visibleColumnsIds)}
          >
            Set Default
          </Button>
        )}
        <Button
          onClick={() =>
            setHiddenColumns([...hiddenColumns, ...optionalColumns])
          }
          size="small"
        >
          Clear
        </Button>
      </Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext items={selectableColumns}>
          <Box sx={{display: "flex", flexWrap: "wrap", rowGap: 1}}>  
          {selectableColumns.map((column) => (
            <SortableItem key={column.id} id={column.id} column={column} hidden={activeId === column.id} />
          ))}
          </Box>
        </SortableContext>
        <DragOverlay>
          {activeId ? <Item id={activeId} selected={visibleColumnsIds.includes(activeId)} label={selectedColumn ? selectedColumn.Header?.toString() : undefined} /> : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
export default ColumnVisibilityToggleChips;


const setVisualProps = (props: any) => ({
  ...props,
  onDelete: props.checked ? () => {} : undefined,
  color: props.checked ? "primary" : "default",
  variant: props.checked ? "default" : "outlined",
});


const SortableItem = ({
  id,
  column,
  hidden = false,
}: {
  id: string;
  column: ColumnInstance<object>;
  hidden: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Chip
      label={column.Header}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation()
        column.toggleHidden()}
      }
      sx={{ cursor: "grab", minWidth: "100px", marginX: "2px", visibility: hidden ? "hidden" : undefined, ...style }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      deleteIcon={<Done style={{ display: "none" }} />}
      {...column.getToggleHiddenProps(setVisualProps)}
    />
  );
};


interface ItemProps {
  id: string;
  selected: boolean;
  label: string | undefined
}
const Item: FC<ItemProps> = forwardRef((props, ref:React.ForwardedRef<HTMLDivElement | null>) => {
  return <Chip label={props.label || props.id} ref={ref} color="primary" variant={props.selected ? "filled" : "outlined"} sx={{minWidth: "100px"}} />
})

