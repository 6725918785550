import DeleteForever from "@mui/icons-material/DeleteForever";
import {
  Box,
  ButtonGroup,
  ButtonProps,
  Button,
  IconButton,
  SvgIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import BillOfLadingIcon from "@mui/icons-material/AssignmentOutlined";
import ConfirmationModal from "components/ConfirmationModal";
import { isValid } from "date-fns";
import { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import Movement, { MovementStatus } from "store/models/Movement";
import { MMDDYYYY, toMMDDYYYY, isMMDDYYYY, toDDMMMYYYY, dateFromMMDDYYYY } from "utils/util";
import DateSelector from "components/DateSelector";
import api from "store/api";
import {
  setCurrentBillOfLadingId,
  setBillOfLadingMovementId,
  selectCurrentContract,
  setContract,
  setMovementContractId,
  setCurrentMovementId,
} from "store/slices/contractSlice";
import { setBladeMode, setGlobalMessage, setModalMode, simpleGlobalMessage } from "store/slices/systemSlice";
import usePermissions, {
  FeatureType,
  ActionType,
  UIActionType,
} from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import { setManyAssets } from "store/slices/assetSlice";
import { movementApi } from "store/services/movement";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Iconify from "components/Iconify";

interface Props {
  movement: Movement;
  isEditable?: boolean;
  disabled?: boolean;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const AssetMovement: FC<Props> = ({ movement, isEditable, disabled, changeIsSubmitting }) => {
  const dispatch = useAppDispatch();
  const currentContract = useSelector(selectCurrentContract);
  const checkBOLPermissions = usePermissions(FeatureType.BILL_OF_LADING);
  const userCanViewBOLs = checkBOLPermissions(ActionType.READ);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditMovements = checkMovementPermissions(ActionType.UPDATE);

  const viewBillOfLading = () => {
      const userCanReadBOLs = checkBOLPermissions(
        ActionType.READ,
        UIActionType.ONCLICK
      );
      if (!userCanReadBOLs) return;
      dispatch(setMovementContractId(currentContract?._id));
      dispatch(setCurrentBillOfLadingId(movement.billOfLading));
      dispatch(
        setModalMode(
          movement.billOfLading ? "billOfLadingViewEdit" : "billOfLadingCreate"
        )
      );
      dispatch(setBillOfLadingMovementId(movement._id));
    
  };

  const removeMovement = () => {
    const userCanEditMovements = checkMovementPermissions(
      ActionType.UPDATE,
      UIActionType.ONCLICK
    );
    if (!userCanEditMovements || !currentContract) return;

    if (movement.status === MovementStatus.completed) {
      dispatch(simpleGlobalMessage("You cannot remove COMPLETED movements"))
      return;
    }

    changeIsSubmitting(true);

    api.contracts.updateContractMovements(
      currentContract._id,
      [movement],
      "Remove",
      {
        onData: ({ contract, assets }) => {
          dispatch(setContract(contract));
          dispatch(setManyAssets(assets));
        },
        onComplete: () => {
          changeIsSubmitting(false);
          dispatch(
            simpleGlobalMessage("Successfully removed movement", "success")
          );
          dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
        },
        onError: (message) => {
          changeIsSubmitting(false);
          dispatch(
            setGlobalMessage({messageText: message, severity: "error", show: true})
          );
        }
      }
    );
  };

  const handleDateOfMoveInputChange = (_: unknown, enteredDate?: string) => {
    if (!currentContract) return;
    const newDate = enteredDate as MMDDYYYY;
    if (isMMDDYYYY(newDate) && isValid(new Date(newDate))) {
      api.contracts.updateContractMovements(
        currentContract._id,
        [{ ...movement, dateOfMove: toMMDDYYYY(newDate) }],
        "Update",
        {
          onData: (data) => {
            dispatch(setContract(data.contract));
            dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
          },
        }
      );
    }
  };

  const handleDateOfMoveAccept = (date: unknown) => {
    if (!currentContract) return;
    const newDate = date as Date;
    if (newDate && isValid(new Date(newDate))) {
      api.contracts.updateContractMovements(
        currentContract._id,
        [{ ...movement, dateOfMove: toMMDDYYYY(newDate) }],
        "Update",
        {
          onData: (data) => {
            dispatch(setContract(data.contract));
            dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
          },
        }
      );
    }
  }

  function updateMovement(status: MovementStatus) {
    if (!currentContract || status === movement.status) return;
    if (currentContract.status === "CONCLUDED") {
      dispatch(
        simpleGlobalMessage(
          "You cannot change the status of movements on concluded contracts"
        )
      );
      return;
    }
    changeIsSubmitting(true);
    api.contracts.updateMovementStatus(
      currentContract._id,
      movement._id,
      status,
      {
        onData: (data) => {
          dispatch(setContract(data.contract));
          dispatch(setManyAssets(data.assets));
        },
        onComplete: (message) => {
          dispatch(
            setGlobalMessage({
              messageText: message,
              severity: "success",
              show: true,
            })
          );
          changeIsSubmitting(false)
          dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
        },
        onError: () => changeIsSubmitting(false),
      }
    );
  }
  
  function handleChangeMovementStatus() {
    if (disabled) {
      dispatch(
        simpleGlobalMessage(
          "These movements can only be edited in the movement module"
        )
      );
    }
  }

  function openMovementBlade() {
    dispatch(setCurrentMovementId(movement._id));
    dispatch(setMovementContractId(movement.contract));
    dispatch(setBladeMode("movementViewEdit"));
  }

  return isEditable ? (
    <Box
      key={movement._id}
      sx={{
        display: "grid",
        justifyItems: "start",
        alignItems: "center",
        gridTemplateColumns: "auto 40px 40px",
        rowGap: 1,
        gridTemplateAreas: `
          "movementStatus movementStatus movementStatus type"
          "dateOfMove remove billOfLading openMovementBlade"`,
        mb: 2,
        "&:not(:first-of-type)": {
          mt: 3
        }
      }}
    >
      <DateSelector
        disabled={disabled || !userCanEditMovements}
        value={movement.dateOfMove}
        className="dateOfMove"
        InputProps={{ startAdornment: movement.tentative ? <Tooltip title="Tentative"><IconButton size="small"><Iconify icon="ph:question" /></IconButton></Tooltip> : undefined }}
        onChange={handleDateOfMoveInputChange}
        onAccept={handleDateOfMoveAccept}
        textFieldProps={{
          size: "small",
          fullWidth: true,
        }}
      />
      <Box
        sx={(theme) => ({
          gridArea: "type",
          justifySelf: "center",
          fontSize: theme.typography.body2,
        })}
      >
        {movement.type.substring(0, 3)}: {" "}
      </Box>
      
      <ConfirmationModal
        handleConfirmation={() => removeMovement()}
        message="Are you sure you want to delete this movement?"
        sx={{
          gridArea: "remove",
        }}
        disabled={!userCanEditMovements || disabled}
      >
        <IconButton color="primary" disabled={!userCanEditMovements || disabled}>
          <DeleteForever />
        </IconButton>
      </ConfirmationModal>
      <Box sx={{ gridArea: "movementStatus" }}>
        <ButtonGroup
          size="small"
          disableElevation
          disabled={!userCanEditMovements}
          onClick={handleChangeMovementStatus}
        >
          {Object.values(MovementStatus).map((status) => {
            const color: ButtonProps["color"] =
              movement.status === status ? "primary" : "inherit";

            return (
              <Button
                disabled={disabled}
                key={status}
                color={color}
                onClick={() => updateMovement(status)}
              >
                {status}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
      <IconButton
        sx={{
          gridArea: "openMovementBlade",
        }}
        onClick={openMovementBlade}
      >
        <SvgIcon>
          <OpenInNew />
        </SvgIcon>
      </IconButton>
      <IconButton
        onClick={viewBillOfLading}
        disabled={!userCanViewBOLs}
        color="primary"
        sx={{
          gridArea: "billOfLading",
        }}
      >
        <SvgIcon color={movement.billOfLading ? "primary" : "disabled"}>
          <BillOfLadingIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  ) : (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr max-content" alignItems="center">
      <Box>
        <Typography color="primary" component="span" sx={{ ml: 1 }}>
          {movement.status}
        </Typography>
      </Box>
      <Typography sx={{ ml: 1 }}>{movement.type}:{" "}</Typography>
      <Typography>{toDDMMMYYYY(dateFromMMDDYYYY(movement.dateOfMove))}</Typography>
      <IconButton
        onClick={openMovementBlade}
        sx={{ ml: 1 }}
      >
        <SvgIcon>
          <OpenInNew />
        </SvgIcon>
      </IconButton>
      <IconButton
        onClick={viewBillOfLading}
        disabled={!userCanViewBOLs}
        color="primary"
        sx={{ ml: 1 }}
      >
        <SvgIcon color={movement.billOfLading ? "primary" : "disabled"}>
          <BillOfLadingIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};

export default AssetMovement;
