import { errorHandler } from "./shared";
import { IInspectionWorkOrder, initialWorkOrderState, IStandardWorkOrder, Priority, WorkOrderStatus, initPurchaseOrder } from 'store/models/WorkOrder';
import { sendToBackend } from 'store/api/util';
import { createMongoId } from "utils/createMongoId";
import store from "store/store";
import { initialTaskState, WorkOrderTask } from "store/models/WorkOrderTask";
import { ICurrency, initCurrency } from "types/Currency";
import { IChecklistItem } from "store/models/Inspection";
import { toMMDDYYYY } from "utils/util";


export const getOneSummary = (
  workOrderId: string,
  actions?: {
    onData?: (data: {data: Buffer}) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }
) => {
  sendToBackend<{data: Buffer}>({
    route: `workOrder/oneSummary?workOrderId=${workOrderId}`,
    method: "GET",
    onData: actions?.onData,
    onError: (message: string) => { errorHandler(message, actions?.onError); },
    onSuccess: actions?.onComplete,
    currentlySubmitting: actions?.currentlySubmitting,
  });
}
export const getBulkSummary = (
  workOrderIds: string[],
  actions?: {
    onData?: (data: {data: Buffer}) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }
) => {
  sendToBackend<{data: Buffer}>({
    route: `workOrder/bulkSummary`,
    method: "POST",
    body: workOrderIds,
    onData: actions?.onData,
    onError: (message: string) => { errorHandler(message, actions?.onError); },
    onSuccess: actions?.onComplete,
    currentlySubmitting: actions?.currentlySubmitting,
  });
}

export const initInspectionWorkOrder = (defaultCurrency: ICurrency["currency"], workOrder?: Partial<IInspectionWorkOrder>) => {
  const users = store.getState().users.users
  const loggedInUserId = store.getState().system.loggedInUserId as string;
  const loggedInUser = users[loggedInUserId]
  return {
    _id: createMongoId(),
    asset: "",
    isBlocking: false,
    dueDate: toMMDDYYYY(new Date()),
    priority: Priority.LOW,
    status: WorkOrderStatus.TO_DO,
    createdOn: toMMDDYYYY(new Date()),
    createdBy: {_id: loggedInUserId, firstName: loggedInUser?.firstName || "", lastName: loggedInUser?.lastName || ""},
    assignedTo: null,
    photos: [],
    completedDate: null,
    number: 0,
    purchaseOrder: initPurchaseOrder({ currency: defaultCurrency }),
    attachments: [],
    ...workOrder,
  } as IInspectionWorkOrder
} 

export const initCheckListItem = (currency: ICurrency["currency"], checkList?: Partial<IChecklistItem>): IChecklistItem => {
  return {
    _id: createMongoId(),
    taskName: "",
    category: "",
    result: "Pass",
    billableToClient: true,
    estimatedHours: 0,
    estimatedMaterials: initCurrency({ currency }),
    title: "",
    dueDate: toMMDDYYYY(new Date()),
    priority: Priority.LOW,
    workOrderCategory: "",
    workOrderSubcategory: "",
    description: "",
    tasks: [],
    photos: [],
    workOrder: null,
    ...checkList,
  }
}

export const initStandardWorkOrder = (defaultCurrency: ICurrency["currency"], workOrder?: Partial<IStandardWorkOrder>): IStandardWorkOrder => {
  const users = store.getState().users.users
  const loggedInUserId = store.getState().system.loggedInUserId as string;
  const loggedInUser = users[loggedInUserId]
  return {
    ...initialWorkOrderState,
    _id: createMongoId(),
    estimatedMaterials: initCurrency({ currency: defaultCurrency }),
    actualMaterialsToComplete: initCurrency(),
    purchaseOrder: initPurchaseOrder({ currency: defaultCurrency }),
    createdBy: {_id: loggedInUserId, firstName: loggedInUser?.firstName || "", lastName: loggedInUser?.lastName || ""},
    ...workOrder,
  } as IStandardWorkOrder;
}

export const initWorkOrderTask = (workOrderTask?: Partial<WorkOrderTask>) => ({
  ...initialTaskState,
  ...workOrderTask
})

