import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  PopoverProps,
  Popover,
  Card,
  Divider,
} from "@mui/material";
import React from "react";
import { ColumnInstance } from "react-table";
import ColumnVisibilityToggleChips from "components/table/ColumnVisibilityToggleChips";

interface Props extends PopoverProps {
  open: boolean;
  close: () => void;
  clearAllFilters: () => void;
  filters: {id: string, value: any}[];
  allColumns: ColumnInstance<object>[];
  optionalColumns: string[];
  hiddenColumns: string[];
  setColumnOrder:  (updater: string[] | ((columnOrder: string[]) => string[])) => void
  setHiddenColumns: (param: string[]) => void;
  visibleColumns: ColumnInstance<any>[]
  tableId?: string;
}

const TableModal: React.FC<Props> = ({
  open,
  close,
  allColumns,
  optionalColumns,
  hiddenColumns,
  clearAllFilters,
  filters,
  setHiddenColumns,
  visibleColumns,
  tableId,
  setColumnOrder,
  ...rest
}) => {
  const setDefaultFilters = () => {
    const filtersToSet = filters.filter(tableFilter => tableFilter.value.length > 0)
    tableId && localStorage.setItem(`filters-${tableId}`, JSON.stringify(filtersToSet))
  }

  return (
    <Popover
      open={open}
      onClose={close}
      {...rest}
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      sx={{ maxWidth: "960px" }}
    >
      <Card>
        <CardHeader title="Table Settings" />
        <Divider />
        <CardContent>
          <ColumnVisibilityToggleChips
            allColumns={allColumns}
            optionalColumns={optionalColumns}
            setHiddenColumns={setHiddenColumns}
            visibleColumns={visibleColumns}
            hiddenColumns={hiddenColumns}
            tableId={tableId}
            setColumnOrder={setColumnOrder}
          />
        </CardContent>
        <Divider />
        <CardContent>
          Filters{" "}
        <Button onClick={setDefaultFilters} size="small">
          Set Defaults
        </Button>
        <Button onClick={clearAllFilters}>Clear</Button>
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={close}>Close</Button>
        </CardActions>
      </Card>
    </Popover>
  );
};
export default TableModal;
