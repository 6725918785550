import React, { FC, KeyboardEvent, UIEvent, createRef, useEffect, useRef, useState } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  TextFieldProps,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessories } from "store/slices/configSlice";
import Iconify from "components/Iconify";
import { useBoolean } from "hooks/useBoolean";
import { setGlobalMessage } from "store/slices/systemSlice";

type Props = Omit<
  AutocompleteProps<string, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  textFieldProps?: TextFieldProps;
  enableConfirm?: boolean;
};

const AccessoryAutoComplete: FC<Props> = ({
  value,
  name,
  error,
  placeholder,
  textFieldProps,
  enableConfirm = false,
  sx,
  onChange,
  ...rest
}) => {
  const customAccessory = useBoolean(false);
  const accessories = useSelector(selectAccessories);
  const customAccessoryInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (value && value.length > 0 && !accessories.settings.includes(String(value))) {
      customAccessory.onTrue();
    }
  }, []);

  const customValue = "+ Create Custom"

  const handleKeyPress = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter' && customAccessoryInputRef.current) {
      handleSubmitCustomAccessory(event);
    }
  };

  function handleSubmitCustomAccessory(event: UIEvent) {
    if (customAccessoryInputRef.current?.value) {
      onChange && onChange(event, String(customAccessoryInputRef.current?.value), "selectOption")
      onChange && onChange(event, null, "clear");
      customAccessory.onFalse();
    } else {
      dispatch(
        setGlobalMessage({
          messageText: "Please type something into the custom accessory field first",
          show: true,
          severity: "warning",
          duration: 2000,
        })
      );
    }
  }

  return (
    <Stack direction="row" alignContent="center" columnGap={1} sx={sx}>
      {customAccessory.value ? (
        <>
          <TextField
            inputProps={{ref: customAccessoryInputRef, form: { autocomplete: "off" },}}
            onKeyDown={handleKeyPress}
            autoComplete="off"
            name={name}
            autoFocus
            value={value}
            error={error}
            placeholder={placeholder}
            onChange={(e) =>
              onChange &&
              onChange(e, e.currentTarget.value, "createOption")
            }
            size="small"
            {...textFieldProps}
          />
          <IconButton
            onClick={(e) => {
              onChange && onChange(e, null, "clear");
              customAccessory.onFalse();
            }}
          >
            <Iconify icon="material-symbols:cancel" />
          </IconButton>
          {enableConfirm && (
          <IconButton
            onClick={(e) => {
              handleSubmitCustomAccessory(e)
            }}
          >
            <Iconify icon="material-symbols:check-circle" />
          </IconButton>
          )}
        </>
      ) : (
        <Autocomplete
          {...rest}
          value={value || null}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
          options={[...accessories.settings, customValue]}
          renderOption={(props, option) => (
            <li {...props}>
              {option === customValue ? <Button fullWidth variant="outlined">{customValue}</Button> : option}
            </li>
          )
          }
          size="small"
          clearOnBlur={false}
          renderInput={(params) => (
            <TextField
              name={name}
              autoComplete="off"
              {...params}
              error={error}
              margin="none"
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
                autocomplete: "new-password",
                form: { autocomplete: "off" },
              }}
              {...textFieldProps}
            />
          )}
          onChange={(_, option, reason): void => {
            if (option === customValue) {
              onChange && onChange(_, "", reason);
              setInputValue('')
              customAccessory.onTrue();
            } else {
              onChange && onChange(_, option, reason);
              setInputValue('')
            }
          }}
        />
      )}
    </Stack>
  );
};

export default AccessoryAutoComplete;
