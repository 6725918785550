import React, { FC, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography, Card } from "@mui/material";
import ImageCarousel from "components/image/ImageCarousel";
import { selectBranches, selectYards } from "store/slices/optionSlice";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { Photo } from "store/models/Photo";
import api from "store/api";
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { selectCurrentAsset, setOneAsset } from "store/slices/assetSlice";
import { useAppDispatch } from 'store/store';
import CopyClipboard from "components/CopyClipboard";
import AttachmentList from "components/AttachmentList";
import { fCurrency, fCurrencyFloat } from "utils/formatNumber";
import { calcNetBookValue, calcAccumulatedAmortization, calcMonthlyDepreciation, assetIsPastUsefulLife, calcResidualValue } from "store/models/Asset";
import { feetToFeetInches } from "utils/util";

const AssetRow: FC = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({ py: 0.5, fontSize: theme.typography.subtitle2 })}
    >
      {children}
    </Box>
  );
};

function AssetField({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="subtitle2"
      sx={{ color: "text.secondary", alignSelf: "center" }}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

interface Props {}

const AssetDetails: React.FC<Props> = () => {
  const currentAsset = useSelector(selectCurrentAsset);
  const currentAssetId = currentAsset?._id;
  const branches = useSelector(selectBranches);
  const yards = useSelector(selectYards);
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<Photo[]>([]);
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getPhotosByAssetId(currentAssetId);
  }, [currentAssetId]);

  const getPhotosByAssetId = (currentAssetId: string) => {
    api.assetPhotos.getAllByAsset(currentAssetId, {
      onData: (assetPhotes) => setImages(assetPhotes),
    });
  };

  const handleImageUpload = (form: FormData) => new Promise((resolve, reject) =>  {
    if(isSubmitting) return;
  
    api.assetPhotos.createOne(currentAssetId, form, {
      onData: (newImage) => {
        const newPhotosArray = [...currentAsset.photos, newImage._id]
        setImages(previousImages => [...previousImages, newImage])
        dispatch(setOneAsset({...currentAsset, photos: newPhotosArray}))
        resolve(newImage)
      },
      onError: (message: string) => {
        dispatch(simpleGlobalMessage(message))
        reject(message);
      },
    });
  });

  const deleteImage = ({ _id: photoId }: Photo) => {
    api.assetPhotos.deleteOne(currentAssetId, photoId, {
      onData: () => {
        const newPhotosArray = currentAsset.photos.filter(photo => photo !== photoId)
        setImages(prevImages => prevImages.filter(image => image._id !== photoId))
        dispatch(setOneAsset({...currentAsset, photos: newPhotosArray}))
      },
      onError: (message: string) => dispatch(simpleGlobalMessage(message)),
    });
  };

  const monthlyDepreciation = assetIsPastUsefulLife(currentAsset) ? 0 : calcMonthlyDepreciation(currentAsset);

  return (
    <>
      {currentAsset && (
        <ImageCarousel
          handleImageUpload={handleImageUpload}
          deleteImage={deleteImage}
          images={images}
          permission={userCanEditAssets}
        />
      )}

      <Card sx={{ p: 2.5, mb: 2 }}>
        <Box display="flex" flexDirection="column" gap="3">
          <AssetRow>
            <AssetField>Asset #:</AssetField>
            {currentAsset?.assetNumber}
          </AssetRow>
          <AssetRow>
            <AssetField>Serial #:</AssetField>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CopyClipboard
                value={currentAsset.serialNumber}
                tooltipText="Copy Serial #"
                successText={`Copied asset Serial #: ${currentAsset.serialNumber}`}
              />
              {currentAsset?.serialNumber}
            </Box>
          </AssetRow>
          <AssetRow>
            <AssetField>Branch:</AssetField>
            {branches[currentAsset.branch].name}
          </AssetRow>
          <AssetRow>
            <AssetField>Yard:</AssetField>
            {yards[currentAsset.yard].name}
          </AssetRow>
          <AssetRow>
            <AssetField>Yard Position:</AssetField>
            {currentAsset.yardPosition}
          </AssetRow>
          <AssetRow>
            <AssetField>Category:</AssetField>
            {currentAsset?.category}
          </AssetRow>
          <AssetRow>
            <AssetField>Sub Category:</AssetField>
            {currentAsset?.subCategory}
          </AssetRow>
          <AssetRow>
            <AssetField>Condition:</AssetField>
            {currentAsset?.condition}
          </AssetRow>
          <AssetRow>
            <AssetField>Branding:</AssetField>
            {currentAsset?.branding}
          </AssetRow>
          <AssetRow>
            <AssetField>Manufacturer:</AssetField>
            {currentAsset?.manufacturer}
          </AssetRow>
          <AssetRow>
            <AssetField>Hours:</AssetField>
            {currentAsset?.hrs}
          </AssetRow>
          <Grid container item xs={12}>
            <Typography sx={{ color: "text.secondary", mr: 2 }} gutterBottom>
              Notes:
            </Typography>
            {currentAsset?.notes}
          </Grid>
          <AssetRow>
            <AssetField>Year Of Manufacture</AssetField>
            {currentAsset.yearOfManufacture}
          </AssetRow>
          <AssetRow>
            <AssetField>Building Code:</AssetField>
            {currentAsset?.buildingCode}
          </AssetRow>
          <AssetRow>
            <AssetField>A/C Type:</AssetField>
            {currentAsset?.acType}
          </AssetRow>
          <AssetRow>
            <AssetField>Heating Type</AssetField>
            {currentAsset.heatingType}
          </AssetRow>
          <AssetRow>
            <AssetField>Cladding:</AssetField>
            {currentAsset?.cladding}
          </AssetRow>
          <AssetRow>
            <AssetField>Electrical:</AssetField>
            {currentAsset?.electrical}
          </AssetRow>
          <AssetRow>
            <AssetField>Fuel Type:</AssetField>
            {currentAsset?.fuelType}
          </AssetRow>
          <AssetRow>
            <AssetField>Floor Style:</AssetField>
            {currentAsset?.floorStyle}
          </AssetRow>
          <AssetRow>
            <AssetField>Size Code:</AssetField>
            {currentAsset?.sizeCode}
          </AssetRow>
          <AssetRow>
            <AssetField>Height:</AssetField>
            {feetToFeetInches(currentAsset?.height)}
          </AssetRow>
          <AssetRow>
            <AssetField>Structural Limit:</AssetField>
            {currentAsset?.structuralLimit}
          </AssetRow>
          <AssetRow>
            <AssetField>Frame</AssetField>
            {currentAsset.frame}
          </AssetRow>
          <AssetRow>
            <AssetField>Colour</AssetField>
            {currentAsset.colour}
          </AssetRow>
          <AssetRow>
            <AssetField># of Washrooms</AssetField>
            {currentAsset.wash}
          </AssetRow>
          <AssetRow>
            <AssetField>Partitions:</AssetField>
            {currentAsset?.partitions}
          </AssetRow>
          <AssetRow>
            <AssetField>Complex:</AssetField>
            {currentAsset?.complex}
          </AssetRow>
          <AssetRow>
            <AssetField>Axle Count</AssetField>
            {currentAsset.axleCount}
          </AssetRow>
          <AssetRow>
            <AssetField>Wind Rating</AssetField>
            {currentAsset.windRating}
          </AssetRow>
          <AssetRow>
            <AssetField>Roof Type</AssetField>
            {currentAsset.roofType}
          </AssetRow>
          <AssetRow>
            <AssetField>In Service Date:</AssetField>
            {currentAsset.inServiceDate}
          </AssetRow>
          <AssetRow>
            <AssetField>Orientation:</AssetField>
            {currentAsset?.orientation}
          </AssetRow>
          <AssetRow>
            <AssetField>Capital Cost:</AssetField>
            {fCurrencyFloat(currentAsset.capitalCost.amount)}
          </AssetRow>
          <AssetRow>
            <AssetField>Accumulated Impairment</AssetField>
            {fCurrency(currentAsset.accumulatedImpairment.amount)}{" "}
            {currentAsset.accumulatedImpairment.currency}
          </AssetRow>
          <AssetRow>
            <AssetField>Useful Life (yrs):</AssetField>
            {currentAsset?.usefulLife}
          </AssetRow>
          <AssetRow>
            <AssetField>Residual Value:</AssetField>
            {fCurrency(calcResidualValue(currentAsset))}
          </AssetRow>
          <AssetRow>
            <AssetField>Net Book Value (Starfleet Calc)</AssetField>
            {fCurrency(calcNetBookValue(currentAsset))}
          </AssetRow>
          <AssetRow>
            <AssetField>Monthly Depreciation:</AssetField>
            {fCurrencyFloat(monthlyDepreciation)}
          </AssetRow>
          <AssetRow>
            <AssetField>Accumulated Amortization (Starfleet Calc):</AssetField>
            {fCurrency(calcAccumulatedAmortization(currentAsset))}
          </AssetRow>
        </Box>
      </Card>
      {currentAsset?._id && (
        <Box mt={1}>
          <AttachmentList
            documentId={currentAsset?._id}
            disabled={!userCanEditAssets}
            changeIsSubmitting={setIsSubmitting}
            documentType="asset"
          />
        </Box>
      )}
    </>
  );
};
export default AssetDetails;
