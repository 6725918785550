import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { ClientContact } from "store/models/ClientContact";


export const clientContactApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getClientContactByQuery: builder.query<ClientContact[],Partial<Record<keyof ClientContact, string | number>>>({
      query: (query) => ({url: `/clientContact/query`, method: 'POST', body: query}),
      transformResponse: getResponseBody,
    }),
  }),
});


export const {
  useGetClientContactByQueryQuery,
} = clientContactApi;
