import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Box,
  FilterOptionsState,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ClientContactModal from "components/modals/ClientContactModal";
import { FormikHelpers } from "formik";
import { throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "store/api";
import { initClientContact } from "store/api/clientContact";
import { ClientContact } from "store/models/ClientContact";
import { selectClients } from "store/slices/clientSlice";
import { getFullName } from "utils/util";

interface Props
  extends Omit<
    AutocompleteProps<ClientContact, boolean, boolean, boolean>,
    "renderInput" | "options"
  > {
  clientContact: ClientContact | null;
  handleChange: (value: ClientContact) => void;
  disableCreateNew?: boolean;
  limitResultsToClientId?: string
}



const ClientContactSelect: React.FC<Props> = ({
  clientContact,
  handleChange,
  disableCreateNew = false,
  limitResultsToClientId
}) => {

  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [contactMatches, setContactMatches] = useState<ClientContact[]>([]);
  const [queryLoading, setQueryLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const clients = useSelector(selectClients)
  const fetchContacts = React.useMemo(
    () =>
      throttle((searchString: string) => {
        setQueryLoading(true);
        api.clientContacts.search(searchString, limitResultsToClientId, {
          onData: (data: ClientContact[]) => {
            setContactMatches(data);
          },
          onComplete: () => setQueryLoading(false),
          onError: () => setQueryLoading(false),
        });
      }, 500),
    []
  );

  const handleSubmit = (
    values: ClientContact,
    formikHelpers: FormikHelpers<ClientContact>
  ) => {
    api.clientContacts.createOne(values, {
      onData: (data) => handleChange(data),
      onComplete: () => {
        formikHelpers.setSubmitting(false)
        setShowNewContactModal(false)}
    });
  };

  function addNewContactOptionIfNoClientMatch(
    options: ClientContact[],
    params: FilterOptionsState<ClientContact>
  ) {
    const filteredOptions = [...contactMatches];
    if (params.inputValue && filteredOptions.length === 0 && !disableCreateNew) {
      filteredOptions.push(
        initClientContact({firstName: "Create New Contact" })
      );
    }
    return filteredOptions;
  }

  const handleSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ClientContact | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ClientContact> | undefined
  ) => {
    if (value && value.firstName.includes("Create New Contact")) {
      setShowNewContactModal(true);
    } else if (value) {
      handleChange(value);
    }
  };
  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    setSearchInput(value)
  }



  useEffect(() => {
    searchInput && fetchContacts(searchInput);
  }, [searchInput]);


  return (
    <>
      <Autocomplete
        key={String(clientContact)}
        options={contactMatches}
        autoComplete
        loading={queryLoading}
        value={clientContact}
        filterOptions={addNewContactOptionIfNoClientMatch}
        includeInputInList
        filterSelectedOptions
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Contacts"
            variant="outlined"
            inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
          />
        )}
        fullWidth
        size="small"
        sx={{ my: 2 }}
        getOptionLabel={option => getFullName(option)}
        renderOption={(props, option) => {
          return (
            <ListItem sx={{ display: "flex", flexDirection: "column", my: 2 }} {...props}>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography>{getFullName(option)}</Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.companyRole},
                </Typography>
              </Box>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.currentClient && clients[option.currentClient].companyName}
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.email}
                </Typography>
              </Box>
            </ListItem>
          );
        }}
      />
    {showNewContactModal && <ClientContactModal open={showNewContactModal} setOpen={setShowNewContactModal} handleSubmit={handleSubmit} clientId={limitResultsToClientId} />}
    </>
  );
};

export default ClientContactSelect;
