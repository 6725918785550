import { selectAssets } from 'store/slices/assetSlice';
import ReactTable, { TableActionProps } from 'components/table/ReactTable';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { dateFromMMDDYYYY, toDDMMMYYYY, getFullName } from 'utils/util';
import ExcelFilter from 'components/table/filters/ExcelFilter';
import { CellProps, Column } from 'react-table';
import TextFilter from 'components/table/filters/TextFilter';
import { selectUsers } from 'store/slices/userSlice';
import { useSelector } from 'react-redux';
import { setCurrentWorkOrderId } from 'store/slices/maintenanceSlice';
import { setBladeMode } from 'store/slices/systemSlice';
import DateRangeFilter from 'components/table/filters/DateRangeFilter';
import { useAppDispatch } from 'store/store';
import AssetAutocompleteFilter from 'components/table/filters/AssetAutocompleteFilter';
import { richTextToString } from 'utils/formatUtils';
import AssetNumberAutoCompleteFilter from 'components/table/filters/AssetNumberAutoCompleteFilter';
import { WebWOQueryResponse } from 'store/services/workOrder';

interface Props {
  workOrders: WebWOQueryResponse[];
  tableActions?: ({ selectedRows, toggleAllRowsSelected }: TableActionProps) => JSX.Element[];
  enableRowSelection?: boolean;
  onRowClick?: (workOrder:WebWOQueryResponse) => void;
  tableId?: string;
  isLoading?: boolean;
}

const MaintenanceTable: FC<Props> = ({ workOrders, tableActions, enableRowSelection, onRowClick, tableId = "maintenance-table", isLoading = false }) => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const assets = useSelector(selectAssets);
  const tableData = workOrders.map(workOrder => { return {
    ...workOrder, category: workOrder.inspection ? "INSPECTION" : workOrder.category
  }})

  const onWorkOrderRowClick = (value: WebWOQueryResponse) => {
    if(onRowClick){
      onRowClick(value);
      return;
    }
    dispatch(setCurrentWorkOrderId(value._id));
    dispatch(setBladeMode("workOrderViewEdit"));
  };

  const renderAssignedTo = (
    value: PropsWithChildren<CellProps<WebWOQueryResponse, string | null>>
  ) => {
    const user = value.row.original.assignedTo;
    return user ? getFullName(user) : "Unassigned";

  };

  const columns: Column<WebWOQueryResponse>[] = useMemo(() => [
    {
      Header: 'WO #',
      accessor: 'number',
      Filter: TextFilter,
      width: 100
    },
    {
      Header: 'Title',
      id: 'title',
      accessor: (original: WebWOQueryResponse) => original.inspection ? original.inspectionType : original.title || "",
      Filter: TextFilter,
      width: 300
    },
    {
      Header: 'Description',
      accessor: (original) => original.description ? richTextToString(original.description) : "",
      id: 'description',
      Filter: TextFilter
    },
    {
      Header: 'Hours',
      accessor: 'estimatedHours',
      width: 100,
    },
    {
      Header: 'PO #',
      accessor: (original) => original.purchaseOrder?.poNumber,
      id: "poNumber",
      Filter: TextFilter
    },
    {
      Header: 'Serial #',
      id: "serialNumber",
      accessor: (original) => original.asset._id,
      filter: "filterByAssetId",
      Filter: AssetAutocompleteFilter,
      Cell: (value: any) => value.cell.row.original.asset.serialNumber,
    },
    {
      Header: 'Asset #',
      accessor: (original) => original.asset._id,
      id: "assetNumber",
      filter: "filterByAssetId",
      Filter: AssetNumberAutoCompleteFilter,
      Cell: (value: any) => value.cell.row.original.asset.assetNumber,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: ExcelFilter,
      filter: 'multiSelect',
    },
    {
      Header: 'Assigned To',
      id: "assignedTo",
      accessor: ({assignedTo}) => assignedTo?._id ? getFullName(assignedTo) : "Unassigned",
      filter: "filterByAssignedTo",
      Filter: ExcelFilter,
      Cell: renderAssignedTo,
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      Filter: DateRangeFilter,
      filter: "dateRange",
      sortType: "stringDateSort",
      Cell: (value: any) => toDDMMMYYYY(dateFromMMDDYYYY(value.cell.value)) || "",
    },
    {
      Header: 'Priority',
      accessor: 'priority',
      Filter: ExcelFilter,
      filter: 'multiSelect',
    },
    {
      Header: 'Category',
      accessor: 'category',
      Filter: ExcelFilter,
      filter: 'multiSelect',
    },
    {
      Header: 'Subcategory',
      accessor: 'subcategory',
      Filter: ExcelFilter,
      filter: 'multiSelect',
    },
    {
      Header: 'Billable',
      accessor: 'billableToClient',
      Filter: ExcelFilter,
      filter: 'createIsTrueFilter',
      optionNameParser: (value: any) => String(value) === "true" ? "Yes" : "No",
      Cell: (value: any) => value.cell.row.original.category !== "INSPECTION" ? value.cell.value ? "Yes" : "No" : "-",
    },
  ], [users, assets]);

  return (
    <ReactTable
      id={tableId}
      data={tableData}
      columns={columns}
      onRowClick={(row) => onWorkOrderRowClick(row.original)}
      tableActions={tableActions}
      enableRowSelection={enableRowSelection}
      optionalColumns={[
        "serialNumber",
        "description",
        "poNumber",
        "category",
        "priority",
        "subcategory",
        "dueDate",
        "status",
        "assignedTo",
        "asset",
        "title",
        "description",
        "estimatedHours",
        "number",
        "assetNumber",
      ]}
      isLoading={isLoading}
    />
  );
}

export default MaintenanceTable;