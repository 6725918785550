import Save from "@mui/icons-material/Save";
import { Box, Button, Card, CardMedia, Grid, TextField, useTheme } from "@mui/material";
import CurrencyTextField from "components/CurrencyTextField";
import { UserAutoComplete } from "components/autocomplete";
import AddressInput from "components/google-map/AddressInput";
import { mapIds } from "components/google-map/AddressSelectDialog";
import PinWithHole from "components/google-map/PinWithHole";
import { Formik, Form, FormikHelpers } from "formik";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import api from "store/api";
import Address, { defaultAddress } from "store/models/Address";
import { Branch } from "store/models/Branch";
import UserData from "store/models/UserData";
import { selectBranches, setOneBranch } from "store/slices/optionSlice";
import { selectLoggedInUser, setGlobalMessage } from "store/slices/systemSlice";
import { selectUsers } from "store/slices/userSlice";
import { useAppDispatch } from "store/store";
import { parsePhoneNumber } from "utils/util";

interface Props {
  branchId: string;
}

const BranchDetails: React.FC<Props> = ({ branchId }) => {
    const branches = useSelector(selectBranches);
    const branch = branches[branchId];
    const users = useSelector(selectUsers);
    const [address, setAddress] = useState<Address>(branch.address || defaultAddress)
    const loggedInUser = useSelector(selectLoggedInUser)
    const checkBranchPermissions = usePermissions(FeatureType.BRANCH)
    const userCanUpdateBranches = checkBranchPermissions(ActionType.UPDATE) && loggedInUser?.branches.includes(branchId)
    const dispatch = useAppDispatch();
    const handleSubmit = (
      values: Branch,
      formikHelpers: FormikHelpers<Branch>
    ) => {
      const {setSubmitting } = formikHelpers
      api.branches.updateOne(values, {
        onData: (data) => {
          dispatch(setOneBranch(data));
          setSubmitting(false)
          dispatch(setGlobalMessage({show: true, severity: "success", messageText: `Successfully updated ${branch.name} branch`}))
        },
        onError: () => {
          setSubmitting(false)
        }
      });
    };

  const mapRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  useEffect(() => {
    loadMap()
  },[branch.address.latLng.lat, address.latLng.lat])
  
  useEffect(() => {
    setAddress(branch.address)
  },[branch.address])

  async function loadMap() {
    const mapsAddress = Boolean(branch.address.latLng.lat) ? {center: {lat: parseInt(address.latLng.lat), lng: parseInt(address.latLng.lng)}, zoom: 7} : defaultAddress
    const map = new window.google.maps.Map(mapRef.current!, {
        ...mapsAddress,
        mapId: theme.palette.mode === "light" ? mapIds.light : mapIds.dark,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        zoomControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative", // Set to "none" to disable ability to move map around
      });
      const marker = new window.google.maps.Marker({
        position:  {lat: parseInt(address.latLng.lat), lng: parseInt(address.latLng.lng)} || undefined,
        map,
        draggable: true,
        icon: {
          path: PinWithHole,
          fillColor: theme.palette.primary.main,
          fillOpacity: 1,
          strokeColor: theme.palette.primary.dark,
          strokeWeight: 1,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(12, 22),
        },
      });
  
  }

  return (
    <Grid container sx={{ p: 3, pt: 5 }}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ ...branch }}
          onSubmit={handleSubmit}
          enableReinitialize
          disabled={!userCanUpdateBranches}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            values,
          }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      minWidth: "200px",
                      width: { md: "inherit", xs: "100%" },
                    }}
                    disabled={_.isEqual(branch, values)}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="name"
                      label="Branch Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={({ target }) =>
                        setFieldValue("name", target.value.trim())
                      }
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "off",
                        form: { autoComplete: "off" },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <UserAutoComplete
                      name="contact"
                      label="Main Contact"
                      value={values.contact ? users[values.contact] : null}
                      enableClearOnBlur
                      onChange={(_, user) =>
                        setFieldValue(
                          "contact",
                          (user as UserData)?._id || null
                        )
                      }
                      onBlur={handleBlur}
                      fullWidth
                      data-testid="assignedTo"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="phone"
                      label="Phone Number"
                      value={parsePhoneNumber(values.phone)}
                      onChange={(event) =>
                        setFieldValue(
                          "phone",
                          parsePhoneNumber(event.currentTarget.value)
                        )
                      }
                      onBlur={handleBlur}
                      type="phoneNumber"
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      inputProps={{
                        "data-testid": "phoneNumber",
                        form: { autocomplete: "off" },
                      }}
                      autoComplete="none"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="fax"
                      label="Fax Number"
                      value={parsePhoneNumber(values.fax)}
                      onChange={(event) =>
                        setFieldValue(
                          "fax",
                          parsePhoneNumber(event.currentTarget.value)
                        )
                      }
                      onBlur={handleBlur}
                      type="phoneNumber"
                      fullWidth
                      error={Boolean(touched.fax && errors.fax)}
                      inputProps={{
                        "data-testid": "phoneNumber",
                      }}
                      autoComplete="none"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CurrencyTextField
                      fullWidth
                      label="Labour Cost"
                      value={values.labourCost}
                      onChange={(event) => {
                        setFieldValue(
                          "labourCost.amount",
                          Number(event.target.value) || undefined
                        );
                      }}
                      onCurrencyChange={(event) => {
                        event.target.value &&
                          setFieldValue(
                            "labourCost.currency",
                            values.country === "CAN" ? "CAD" : "USD"
                          );
                      }}
                      disableCurrencySelect
                      error={Boolean(touched.labourCost && errors.labourCost)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      name="costCenter"
                      label="Cost Center"
                      value={values.costCenter}
                      onChange={(event) => {
                        setFieldValue(
                          "costCenter",
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      fullWidth
                      error={Boolean(touched.costCenter && errors.costCenter)}
                      inputProps={{
                        "data-testid": "costCenter",
                      }}
                      autoComplete="none"
                    />
                  </Grid>

                  <Grid item xs={9}>
                    <AddressInput
                      onAddressSelect={(address) => {
                        setFieldValue("address", address);
                        setAddress(address);
                      }}
                      address={values.address}
                      showAddress
                      restrictToCountry={branch.country === "CAN" ? "ca" : "us"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name="country"
                      label="Country"
                      value={values.country}
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      disabled
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "off",
                        form: { autoComplete: "off" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardMedia
                      sx={{ borderRadius: 1, height: 440, width: "100%" }}
                      ref={mapRef}
                      component="div"
                      id="googleMap"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BranchDetails;
