import { addWeeks } from 'date-fns';
import { Asset } from 'store/models/Asset';
import { AssetDeliverable } from 'store/models/AssetDeliverable';
import { Contract } from 'store/models/Contract';
import { MMDDYYYY, not, notAll } from 'utils/util';

const now = new Date();
const aWeekFromNow = addWeeks(now, 1);

export const determineTotalNumberOfContractActionsRequired = (contracts: {[_id: string]: Contract}, assets: {[_id: string]: Asset}) => {

    let actionsRequired = 0;
    for (const contract of Object.values(contracts)) {
        if (determineActionRequiredOnContract(contract,assets).actionName) actionsRequired++
    }

    return actionsRequired;
}

export const determineActionRequiredOnContract = (contract: Contract, assets: {[_id: string]: Asset}): { actionName: string; urgency: "none" | "yellow" | "red" } => {

    const {contractSigned, projectNumber, status, assetDeliverables, outDate, endDate} = contract

    const activeAssetDeliverablesPopulated = assetDeliverables
    .map(deliverable => ({...deliverable, asset: assets[`${deliverable.asset}`] as Asset | undefined}))
    .filter((deliverable) => deliverable.isActiveOnContract);

    let actionRequired = {
            actionName: "",
            urgency: "none" as "none" | "yellow" | "red"
    };

    // Contracts that are awaiting approval need to be approved/denied
    if (status === "AWAITING APPROVAL") {
        actionRequired.actionName = "Approve/Deny"
        if (projectNumber === "4444") {
            isLessThanAWeekFromNow(outDate)
        }
        // Contracts who need to go out within a week are red level urgent
        actionRequired.urgency = isLessThanAWeekFromNow(outDate) ? "red" : "yellow"
        return actionRequired
    }

    // Contracts that are AWAITING CONFIRMATION with any of the following need to have this information filled in:
    // invalid project number
    // missing client signature
    // missing asset rental rates
    if (status === "AWAITING CONFIRMATION" && (!projectNumber || !contractSigned || notAll(assetDeliverablesAllHaveValidRentalRates(activeAssetDeliverablesPopulated)))) {
        actionRequired.actionName = "Missing Information"
        // Contracts who need to go out within a week are red level urgent
        actionRequired.urgency = isLessThanAWeekFromNow(outDate) ? "red" : "yellow"
        return actionRequired
    }

    // Contracts that are AWAITING CONFIRMATION and have a valid project number, signature and rental rates need to be marked as pending delivery
    if (status === "AWAITING CONFIRMATION" && projectNumber && contractSigned && assetDeliverablesAllHaveValidRentalRates(activeAssetDeliverablesPopulated)) {
        actionRequired.actionName = "Mark Pending Delivery"
        // Contracts who need to go out within a week are red level urgent
        actionRequired.urgency = isLessThanAWeekFromNow(outDate) ? "red" : "yellow"
        return actionRequired
    }
    
    // Contracts that are PENDING DELIVERY with assets needing delivery need to have their assets delivered
    if (status === "PENDING DELIVERY" && atLeastOneAssetDeliverableNeedsToBeDelivered(activeAssetDeliverablesPopulated)) {
        actionRequired.actionName = "Delivery Needed"
        // Contracts who need to go out out starting today or in the past are red level urgent
        actionRequired.urgency = isInThePast(outDate) ? "red" : "yellow"
        return actionRequired
    }

    // Contracts that are PENDING DELIVERY with all assets delivered need to be marked active
    if (status === "PENDING DELIVERY" && allAssetsAreDelivered(activeAssetDeliverablesPopulated)) {
        actionRequired.actionName = "Mark Active"
        // Contracts who need to be marked active should be immediately done so always
        actionRequired.urgency = "red";
        return actionRequired
    }

    // This check required a guard for a null endDate
    if (endDate) {
    // Contracts that are ACTIVE and have ended with Assets still ON LEASE need to be checked in
    if (status === "ACTIVE" && isInThePast(endDate) && atLeastOneAssetDeliverableIsOnLease(activeAssetDeliverablesPopulated)) {
        actionRequired.actionName = "Check in Assets"
        // Contracts with assets needing to be checked in are always red level urgent
        actionRequired.urgency = "red"
        return actionRequired
    }
    }

    // Contracts that are ACTIVE with all assets checked in need to be concluded
    if (
      !activeAssetDeliverablesPopulated.length &&
      status === "ACTIVE"
    ) {
      actionRequired.actionName = "Conclude Contract";
      // Contracts that are ready to be concluded are always red level urgent
      actionRequired.urgency = "red";
      return actionRequired;
    }

    return actionRequired

}

const assetDeliverablesAllHaveValidRentalRates = (assetDeliverables: AssetDeliverable<Asset | undefined>[]) => {
    // Returns true if all rental rates are set
    for (const assetDeliverable of assetDeliverables) {
        const {rentalRate} = assetDeliverable;
        if (rentalRate.amount === 0 || rentalRate == null) {
        return false
        }
    }
    return true
}

const atLeastOneAssetDeliverableNeedsToBeDelivered = (assetDeliverables: AssetDeliverable<Asset | undefined>[]) => {
    // Returns true if a single SOLD PENDING DELIVERY asset is found
    for (const assetDeliverable of assetDeliverables) {
        if (assetDeliverable?.asset?.status === "SOLD PENDING DELIVERY") {
        return true
        }
    }
    return false
}

const atLeastOneAssetDeliverableIsOnLease = (assetDeliverables: AssetDeliverable<Asset | undefined>[]) => {
    // Returns true if a single ON LEASE asset is found
    for (const assetDeliverable of assetDeliverables) {
        if (assetDeliverable.asset?.status === "ON LEASE") {
        return true
        }
    }
    return false
}

const allAssetsAreDelivered = (assetDeliverables: AssetDeliverable<Asset | undefined>[]) => {
    return not(atLeastOneAssetDeliverableNeedsToBeDelivered(assetDeliverables))
}

const isLessThanAWeekFromNow = (date: Date | MMDDYYYY) => {
    const diff =   Number(aWeekFromNow) - Number(new Date(date));
    return diff > 0 ? true : false;
}

const isInThePast = (date: Date | MMDDYYYY) => {
    const diff = Number(new Date()) - Number(date);
    return diff > 0 ? true : false
}