import { Theme } from "@mui/material";
import { FC } from "react";
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from 'theme/overrides/CustomIcons';

const RowCheckbox: FC<{
  indeterminate?: boolean;
  checked?: boolean;
  onChange?: Function;
  disabled?: boolean;
}> = ({ checked, onChange, indeterminate, ...rest }) => {
  return indeterminate ? (
    <CheckboxIndeterminateIcon
      sx={(theme) => ({ fill: theme.palette.primary.main })}
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        onChange && onChange(e);
      }}
    />
  ) : checked ? (
    <CheckboxCheckedIcon
      sx={{ fill: (theme: Theme) => theme.palette.primary.main }}
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        onChange && onChange(e);
      }}
    />
  ) : (
    <CheckboxIcon
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        onChange && onChange(e);
      }}
    />
  );
};

export default RowCheckbox;
