import React, { useEffect, useState } from "react";
import AssetView from "./view/AssetView";
import { Asset } from "store/models/Asset";
import { downloadAssetPdf } from "utils/util";
import api from "store/api";
import { useSelector } from 'react-redux';
import { selectBladeMode, selectLoggedInUser, setBladeMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { clearCurrentAssetId, selectCurrentAsset, setOneAsset, setSelectedAssetIds } from 'store/slices/assetSlice';
import usePermissions, { ActionType, FeatureType, UIActionType } from "hooks/usePermissions";
import { CircularProgress, IconButton, SvgIcon, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import { ReactComponent as PdfIcon } from "svg/pdf.svg";
import MaintenanceIcon from '@mui/icons-material/Handyman';
import CancelIcon from '@mui/icons-material/Cancel';
import Blade from "components/Blade";
import PageviewIcon from '@mui/icons-material/PageviewOutlined';
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import AssetForm from "features/assets/AssetForm";
import { Form, Formik, FormikHelpers } from "formik";
import { assetValidationSchema } from "utils/formikAPI";
import { ReactComponent as RentIcon } from "svg/real-estate-sign-house-rent.svg";
import { QRCodeCanvas } from 'qrcode.react';
import { setNewReservation } from "store/slices/contractSlice";
import { initContract } from "store/api/contract";
import UserData from "store/models/UserData";
import { initAsset } from "store/api/asset";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import { useAppDispatch } from 'store/store';
import AsyncButton from "components/upload/AsyncButton";
import { setCreatedClientId } from "store/slices/clientSlice";

interface Props {}

const AssetViewEditBlade: React.FC<Props> = () => {
  const navigate = useNavigate();
  const currentAsset = useSelector(selectCurrentAsset);
  const [pdfLoading, changePdfLoading] = useState(false);
  const bladeMode = useSelector(selectBladeMode);
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE);
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanCreateContract = checkContractPermissions(ActionType.CREATE);
  const [isEditing, setEditing] = useState(false);
  const loggedInUser = useSelector(selectLoggedInUser) as UserData;
  const defaultCurrency = useSelector(selectDefaultUserCurrency(loggedInUser));
  const initialValues = initAsset({...currentAsset, currency: defaultCurrency});
  const location = useLocation();
  const isInAssetModule = location.pathname.includes("assets")
  const disableReservationCreate = !userCanCreateContract || !isInAssetModule;

  const dispatch = useAppDispatch();

  const updateAsset = (asset: Asset, { setSubmitting, resetForm}: FormikHelpers<Asset>) => {
      api.assets.updateOne(asset, `Asset Manually Updated by ${loggedInUser?.email}`, {
        onData: (asset) => {
          dispatch(setOneAsset(asset))
          setEditing(false);
          resetForm();
        },
        onError: () => setSubmitting(false),
      });
  };

  /**
   * Users can otherwise be in edit mode on one asset and jump directly to edit mode on another
   */
  const preventEditHopping = () => setEditing(false);
  const closeBlade = () => dispatch(clearCurrentAssetId());

  useEffect(preventEditHopping, [currentAsset?._id]);

  const editMode = () => {
    const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if(!userCanEditAssets) return;
    setEditing(true);
  }

  const createWorkOrder = () => {
    const userCanCreateWorkOrders = checkMaintenancePermissions(ActionType.CREATE, UIActionType.ONCLICK);
    if (!userCanCreateWorkOrders) return;
    dispatch(setBladeMode("workOrderCreate"));
  }

  const toAssetPageView = () => {
    navigate(`/assets/${currentAsset?.serialNumber}`);
    closeBlade();
  }

  function handleProductionComplete() {
    return new Promise<void>((resolve) => {
      api.assets.updateOne({...currentAsset, inProduction: false }, `Asset set IN PRODUCTION to false by ${loggedInUser?.email}`, {
        onData: (asset) => {
          dispatch(setOneAsset(asset))
        },
        onComplete: resolve
      })
    })
  }

  const createReservation = () => {
    const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if (!currentAsset || !userCanEditAssets) return;

    if (currentAsset.status === 'AVAILABLE') {
      dispatch(setCreatedClientId(undefined))
      dispatch(setSelectedAssetIds([currentAsset._id]));
      dispatch(setNewReservation(initContract({ createdBy: loggedInUser._id }, defaultCurrency)))
      dispatch(setBladeMode("assetReserve"));
    } else {
      dispatch(simpleGlobalMessage('Asset is not available for reservation'));
    }
  }

  const downloadQRCode = () => {
    const qrCodeCanvas = document.querySelector("canvas");
    if(!qrCodeCanvas) return;
    const a = document.createElement("a");
    a.href = qrCodeCanvas.toDataURL();
    a.setAttribute("download", "download.png");
    a.click();
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={assetValidationSchema}
      onSubmit={updateAsset}
    >
      {({ values, isSubmitting, resetForm, submitForm }) => {
        
        const viewMode = () => {
          setEditing(false);
          resetForm()
        }
        
        const bladeActions = () =>
          isEditing
            ? [
                <Tooltip title="Save" key="save">
                  <IconButton
                    disabled={_.isEqual(values, currentAsset)}
                    color="primary"
                    onClick={submitForm}
                    key="submitUpdate"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SaveIcon />
                    )}
                  </IconButton>
                </Tooltip>,
                <IconButton color="primary" onClick={viewMode} key="cancel">
                  <CancelIcon />
                </IconButton>,
              ]
            : [
                <Tooltip title={disableReservationCreate ? "Reservations must be created in Assets module" : "Create New Reservation"} key="new reservation">
                  <span>
                    <IconButton
                      color="primary"
                      disabled={disableReservationCreate}
                      onClick={createReservation}
                      key="rent"
                    >
                      <SvgIcon fontSize="small">
                        <RentIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>,
                <Tooltip title="Download QR Code" key="QR">
                  <IconButton
                    color="primary"
                    onClick={downloadQRCode}
                    data-testid="download-qr-code"
                    key="download-qr-code"
                  >
                    <QrCodeRoundedIcon />
                  </IconButton>
                </Tooltip>,
                <Tooltip title="Save As PDF" key="PDF">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      downloadAssetPdf(currentAsset._id, changePdfLoading)
                    }
                    key="pdf"
                  >
                    {pdfLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SvgIcon fontSize="small">
                        <PdfIcon />
                      </SvgIcon>
                    )}
                  </IconButton>
                </Tooltip>,
                <Tooltip title="Create Work Order" key="new WO">
                  <IconButton
                    color="primary"
                    onClick={createWorkOrder}
                    key="newWorkOrder"
                  >
                    <MaintenanceIcon />
                  </IconButton>
                </Tooltip>,
                <Tooltip title="Edit Asset" key="edit asset">
                  <IconButton
                    key="edit"
                    color="primary"
                    onClick={editMode}
                    disabled={!userCanEditAssets}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>,
              ];

        return (
          <Form>
            <Blade
              title={currentAsset?.inProduction ? (
                <AsyncButton
                  sx={{ marginRight: 1, minWidth: 200 }}
                  size="small"
                  variant="contained"
                  disabled={!userCanEditAssets}
                  onClick={handleProductionComplete}
                >
                  Production Complete
                </AsyncButton>
              ) : undefined}
              actions={bladeActions()}
              open={Boolean(currentAsset)}
              changeOpen={closeBlade}
              onTop={bladeMode === "assetViewEdit"}
            >
              {isEditing ? <AssetForm /> : <AssetView />}
            </Blade>
            <QRCodeCanvas
              value={`${process.env.REACT_APP_URL}/assets/${currentAsset?.serialNumber}`}
              style={{
                width: "100%",
                height: "100%",
                display: "none"
              }}
              size={240}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssetViewEditBlade;
