import {
  DataGridProProps,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
  GridToolbarContainerProps,
  GridRowClassNameParams,
  GridPrintExportOptions,
  GridExportDisplayOptions,
  GridApiPro,
  GridToolbarColumnsButton,
  GridFilterPanel,
  GridFilterModel,
  GridCallbackDetails,
  GridDeleteForeverIcon,
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_ACTIONS_COLUMN_TYPE,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  Box,
  InputAdornment,
  LinearProgress,
  Button,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  alpha,
  Theme,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import { format } from 'date-fns';
import EmptyContent from 'components/empty-content/empty-content';
import usePersistTableSettings from 'hooks/usePersistTableSettings';
import { MutableRefObject, ReactNode, useEffect, useRef } from 'react';
import Iconify from 'components/iconify/iconify';
import pluralize from 'pluralize';
import Tune from '@mui/icons-material/Tune';
import Restore from '@mui/icons-material/Restore';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';

interface ToolbarProps extends GridToolbarContainerProps {
  additionalActions?: React.ReactNode[];
  handleAddNew?: VoidFunction;
  addNewButtonText: string;
  csvOptions?: GridCsvExportOptions;
  fontSize: number;
  handleFontSizeChange: (event: SelectChangeEvent<number>, child: ReactNode) => void;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  disableFontSizeSelector: boolean;
  disableExport: boolean;
  hiddenSelected: number;
}

function StyledToolbar({
    additionalActions,
    handleAddNew,
    fontSize,
    handleFontSizeChange,
    printOptions,
    disableFontSizeSelector,
    disableExport,
    addNewButtonText,
    csvOptions,
    hiddenSelected,
    ...toolbarContainerProps
  }: ToolbarProps) {
    const buttonSize = "medium";
    const fontSizeOptions = [10, 12, 14, 16];

    return (
      <>
        <GridToolbarContainer sx={{ padding: 0 }} {...toolbarContainerProps}>
          <Box
            sx={(theme) => ({
              justifyContent: "flex-end",
              alignItems: "center",
              flexWrap: "wrap",
              display: "flex",
              flex: 1,
              gap: 2,
              [theme.breakpoints.down("sm")]: {
                display: "grid",
                gap: 1,
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            })}
          >
            <Box>
              <GridToolbarQuickFilter
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
                size="small"
                inputProps={{ sx: { height: 19 } }}
              />
              {hiddenSelected ? (
                <Box display="flex" flex={1}>
                  <Typography variant="caption" color="error">
                    {`${pluralize(
                      "row",
                      hiddenSelected,
                      true
                    )} hidden by your current filter`}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box
              flex={1}
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              })}
            />
            {!disableFontSizeSelector && (
              <FormControl
                sx={(theme) => ({
                  width: 120,
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                })}
              >
                <InputLabel id="demo-simple-select-label">Font Size</InputLabel>
                <Select
                  size="small"
                  value={fontSize}
                  label="Font Size"
                  onChange={handleFontSizeChange}
                  sx={{ height: 36 }}
                >
                  {fontSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!disableExport && (
              <GridToolbarExport
                size={buttonSize}
                variant="text"
                csvOptions={{
                  ...csvOptions,
                  fileName: csvOptions?.fileName
                    ? `${csvOptions?.fileName} - ${format(
                        new Date(),
                        "dd-MMM-yyyy"
                      )}`
                    : undefined,
                }}
                printOptions={printOptions}
                sx={(theme: Theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.contrastText
                      : "white",
                })}
              />
            )}
            <GridToolbarDensitySelector
              size={buttonSize}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            <GridToolbarFilterButton
              componentsProps={{
                button: {
                  variant: "text",
                  size: buttonSize,
                },
              }}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            <GridToolbarColumnsButton
              size={buttonSize}
              variant="text"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            {handleAddNew && (
              <Button
                onClick={handleAddNew}
                startIcon={<Iconify icon="eva:plus-fill" />}
                variant="contained"
                color="primary"
                size={buttonSize}
                sx={{ whiteSpace: "nowrap" }}
              >
                {addNewButtonText}
              </Button>
            )}

            {additionalActions}
          </Box>
        </GridToolbarContainer>
        {/* <Typography variant="caption">This is text</Typography> */}
        <Box />
      </>
    );
  };

type FilterPanelProps = GridProSlotProps["filterPanel"] & {
  persistFilter: boolean;
  handleSetUserDefaultFilters: (reason?: string) => void;
  userDefaultFilters: GridFilterModel;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails<any>) => void
};

function StyledFilterPanel({persistFilter, handleSetUserDefaultFilters, userDefaultFilters,handleFilterModelChange, ...props}: FilterPanelProps) {
  const userHasDefaultFilters = Boolean(userDefaultFilters?.items?.length)

  return (
    <Box sx={{ display: "flex" }}>
      {persistFilter && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2
          }}
        >
          <Tooltip title="Set Default Filters">
            <IconButton
              onClick={() => handleSetUserDefaultFilters()}
              color={userHasDefaultFilters ? "primary" : "inherit"}
              sx={{ height: 40 }}
            >
              <Tune />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Table to Default Filters">
            <span>
              <IconButton
                onClick={() =>
                  handleFilterModelChange(userDefaultFilters, {
                    reason: "reset",
                  })
                }
                color="primary"
                disabled={!userHasDefaultFilters}
                sx={{ height: 40 }}
              >
                <Restore />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear Default Filters">
            <span>
              <IconButton
                onClick={() => handleSetUserDefaultFilters("clear")}
                color={userHasDefaultFilters ? "error" : "inherit"}
                disabled={!userHasDefaultFilters}
                sx={{ height: 40 }}
              >
                <GridDeleteForeverIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
      <GridFilterPanel {...props} sx={{ minWidth: "50vw" }} />
    </Box>
  );
}

export interface StyledDataGridProps
  extends Omit<DataGridProProps, 'columnVisibilityMdel' | 'onColumnVisibilityChange'> {
  additionalActions?: React.ReactNode[];
  handleAddNew?: VoidFunction;
  addNewButtonText?: string;
  csvOptions?: GridCsvExportOptions;
  toolbarContainerProps?: GridToolbarContainerProps;
  tableId: string;
  noResultsContent?: { title: string; description?: string; action?: React.ReactNode };
  highlightRow?: (params: GridRowClassNameParams<any>) => string;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  disableFontSizeSelector?: boolean;
  disableExport?: boolean;
  disableToolbar?: boolean;
  persistPagination?: boolean;
  persistPinnedColumns?: boolean;
  persistSort?: boolean;
  persistFilter?: boolean;
  persistColumnOrder?: boolean;
  apiRef?: MutableRefObject<GridApiPro>;
}

const StyledDataGrid = (props: StyledDataGridProps) => {
  const {
    columns,
    rows,
    additionalActions,
    handleAddNew,
    csvOptions,
    toolbarContainerProps,
    tableId,
    sx,
    noResultsContent = { title: 'No Results Found' },
    highlightRow = () => {},
    printOptions,
    disableFontSizeSelector = false,
    disableExport = false,
    addNewButtonText = 'Add New',
    persistPagination = false,
    persistPinnedColumns = false,
    persistFilter = false,
    persistSort = false,
    persistColumnOrder = true,
    disableToolbar = false,
    initialState,
    apiRef,
    pinnedRows,
    ...otherProps
  } = props;
  
  
  const {
    hiddenColumns,
    handleColumnVisibiltyChange,
    handleStateChange,
    tableDensity,
    paginationState,
    handlePaginationChange,
    fontSize,
    handleFontSizeChange,
    sortModel,
    handleSortModelChange,
    filterModel,
    handleFilterModelChange,
    handleColumnOrderChange,
    orderedFields,
    hiddenSelected,
    pinnedColumns,
    handlePinnedColumnsChange,
    handleSetUserDefaultFilters,
    userDefaultFilters,
  } = usePersistTableSettings({
    tableId,
    persistPagination,
    persistSort,
    persistFilter,
    persistColumnOrder,
    persistPinnedColumns,
    initialState,
    orderedFields: [
      GRID_CHECKBOX_SELECTION_FIELD,
      GRID_ACTIONS_COLUMN_TYPE,
      ...columns
        .map((column) => column.field)
        .filter((field) => field !== GRID_ACTIONS_COLUMN_TYPE),
    ],
  });
  
  const gripApiRef = useGridApiRef();
  const apiRefToUse = apiRef || gripApiRef;

  useEffect(() => {
    if (apiRefToUse) {
      apiRefToUse.current.setState((state) => ({
        ...state,
        columns: { ...state.columns, orderedFields },
      }));
    }
  }, [pinnedRows]);
  
  const tableRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  
  return (
    <DataGridPro
      apiRef={apiRefToUse}
      ref={tableRef}
      columns={columns}
      columnVisibilityModel={hiddenColumns}
      onColumnVisibilityModelChange={handleColumnVisibiltyChange}
      onColumnOrderChange={handleColumnOrderChange}
      pinnedRows={pinnedRows}
      pagination
      rows={rows}
      sx={{
        "& > .MuiDataGrid-toolbarContainer": {
          py: 3,
          backgroundColor: theme.palette.background.paper,
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
          {
            outline: "none !important",
          },
        "& .MuiDataGrid-row:hover": {
          cursor:
            otherProps.onRowClick || otherProps.checkboxSelection
              ? "pointer"
              : undefined,
        },
        "& .MuiDataGrid-overlayWrapper": {
          minHeight: 200,
        },
        "& .MuiDataGrid-cell *": {
          fontSize,
        },
        "& .super-app-theme--highlight-row": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },

        "& .MuiDataGrid-virtualScroller": {
          // Base scrollbar style
          "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px",
          },
          // Track style
          "&::-webkit-scrollbar-track": {
            background: theme.palette.background.paper,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "8px",
          },
          // Scrollbar hover style
          "&:hover::-webkit-scrollbar": {
            width: "16px", 
            height: "16px", 
          },
        },

        overFlowX: "scroll",
        fontSize,
        height: !rows.length ? 500 : "auto",
        minHeight: 360,
        ...sx,
      }}
      getRowClassName={(params: GridRowClassNameParams<any>) =>
        `super-app-theme--${highlightRow(params) ? "highlight-row" : ""}`
      }
      paginationModel={paginationState}
      hideFooterRowCount
      hideFooterPagination={false}
      onPaginationModelChange={handlePaginationChange}
      sortModel={sortModel}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={handlePinnedColumnsChange}
      onSortModelChange={handleSortModelChange}
      filterModel={filterModel}
      onFilterModelChange={handleFilterModelChange}
      slots={{
        toolbar: disableToolbar ? null : StyledToolbar,
        filterPanel: StyledFilterPanel,
        loadingOverlay: LinearProgress,
        noResultsOverlay: () => <EmptyContent {...noResultsContent} />,
        noRowsOverlay: () => <EmptyContent {...noResultsContent} />,
      }}
      slotProps={{
        toolbar: {
          handleAddNew,
          addNewButtonText,
          additionalActions,
          fontSize,
          disableFontSizeSelector,
          handleFontSizeChange,
          printOptions,
          csvOptions,
          hiddenSelected,
          disableExport,
          ...toolbarContainerProps,
        },
        filterPanel: {
          persistFilter,
          handleSetUserDefaultFilters,
          userDefaultFilters,
          handleFilterModelChange,
        } as FilterPanelProps,
      }}
      onStateChange={handleStateChange}
      density={tableDensity}
      {...otherProps}
      initialState={{
        ...initialState,
        columns: { ...initialState?.columns, orderedFields },
      }}
    />
  );
};

export default StyledDataGrid;
