import { Card } from "@mui/material";
import { AssetAutoComplete } from "components/autocomplete";
import React from "react";
import { useSelector } from "react-redux";
import { FilterProps } from "react-table";
import { Asset } from "store/models/Asset";
import { selectAssets } from "store/slices/assetSlice";

// Requires the table column to use the _id as the accessor otherwise this filter will not work
// Each column  will also require a unique id

const AssetAutocompleteFilter = <T extends Record<string, unknown>>({
  column,
}: FilterProps<T>) => {
  const { id, filterValue, setFilter, render } = column;
  const assets = useSelector(selectAssets);

  return (
    <Card
      sx={{
        padding: 1,
        borderRadius: 1,
        zIndex: 10,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AssetAutoComplete
        style={{ maxWidth: "100%", minWidth: 240 }}
        value={assets[filterValue] || null}
        id="not-react-table"
        onChange={(_, value) => {
          setFilter((value as Asset)?._id || undefined);
        }}
        textFieldProps={{
          label: render("Header")?.toString(),
          InputLabelProps: { htmlFor: id },
          variant: "outlined",
          autoFocus: true,
          size: "small",
        }}
      />
    </Card>
  );
};
export default AssetAutocompleteFilter;
