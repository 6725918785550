import { Box, Theme, Popover } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { HeaderGroup, HeaderPropGetter } from "react-table";
import FilterIcon from "@mui/icons-material/FilterList";
import { styled, SxProps } from "@mui/material/styles"
import GroupByToggle from "components/table/GroupByToggle";
import SortByToggle from "components/table/SortByToggle";

interface Props {
  headerGroup: HeaderGroup;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  headerProps: (props: HeaderPropGetter<object> | undefined, { column }: any) => any[];
  sx?: SxProps;
}

const MuiColumnHeader = styled(Box)(({theme}) => ({
  fontWeight: 500,
  padding: 16,
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.down("xl")]: {
    padding: 12,
  },
}));

const ColumnHeader: FC<Props> = ({
  headerGroup,
  gotoPage,
  headerProps,
  sx
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const filterId = `${headerGroup.Header}Filter`;

  const ableToFilter = () => {
    return headerGroup.canFilter && hasFilter();

    function hasFilter() {
      return headerGroup.Filter;
    }
  };

  const toggleFilterVisibility = () => {
    setAnchorEl(headerRef.current);
  };

  const highlightIfActive = (theme: Theme) => {

    function isFiltered() {
      return Array.isArray(headerGroup.filterValue) ? Boolean(headerGroup.filterValue.length) : Boolean(headerGroup.filterValue);
    }
    return isFiltered() ? theme.palette.primary.main : "";
  };

  return (
    <MuiColumnHeader {...headerGroup.getHeaderProps(headerProps)} sx={sx} ref={headerRef}>
      <GroupByToggle headerGroup={headerGroup} gotoPage={gotoPage} />
      <SortByToggle headerGroup={headerGroup} />
      {ableToFilter() && (
        <>
          <Box
            sx={{
              marginLeft: "auto",
              paddingRight: 1,
              cursor: "pointer",
            }}
            onClick={toggleFilterVisibility}
          >
            <FilterIcon sx={{ fill: highlightIfActive }} />
          </Box>
          <Popover
            id={filterId}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ mt: 1 }}
          >
            {headerGroup.render("Filter")}
          </Popover>
        </>
      )}
    </MuiColumnHeader>
  );
}

export default ColumnHeader;