import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE, AppDispatch } from "store/store";
import { byIds, populateIds } from "store/sliceUtils";
import { Asset, calcMonthlyDepreciation } from "store/models/Asset";
import api from "store/api";
import { AssetStatus } from "store/models/AssetStatus";
import { Contract } from "store/models/Contract";
import { dateFromMMDDYYYY, displayAddress, MMDDYYYY, toDDMMMYYYY } from "utils/util";
import { selectClients } from "./clientSlice";
import { selectBranches } from "./optionSlice";
import { selectLoggedInUser } from "./systemSlice";
import UserData from "store/models/UserData";
import { fCurrency } from "utils/formatNumber";

export type ASSETS_STORE = {
  assets: Record<string, Asset>;
  assetsLoading: boolean;
  currentAssetId?: Asset["_id"];
  selectedAssetIds: Asset["_id"][];
  checkedAssetIds: Asset["_id"][];
  pinnedAssetIds: Asset["_id"][];
};

const initialState: ASSETS_STORE = {
  assets: {},
  assetsLoading: true,
  selectedAssetIds: [],
  checkedAssetIds: [],
  pinnedAssetIds: [],
  currentAssetId: undefined,
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    setAssetsLoading(state, action: PayloadAction<boolean>) {
      state.assetsLoading = action.payload;
    },
    setOneAsset(state, action: PayloadAction<Asset>) {
      state.assets[`${action.payload._id}`] = action.payload;
    },
    setManyAssets(state, action: PayloadAction<Asset[]>) {
      for (const asset of action.payload) {
        state.assets[asset._id] = asset;
      }
    },
    setAllAssets(state, action: PayloadAction<Asset[]>) {
      state.assets = byIds<Asset>(action.payload);
    },
    setCurrentAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.currentAssetId = action.payload;
    },
    clearCurrentAssetId(state) {
      state.currentAssetId = undefined;
    },
    addSelectedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.selectedAssetIds.push(action.payload);
    },
    removeSelectedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.selectedAssetIds = state.selectedAssetIds.filter(
        (_id) => _id !== action.payload
      );
    },
    clearAllSelectedAssets(state) {
      state.selectedAssetIds = [];
    },
    clearAllPinnedAssets(state) {
      state.pinnedAssetIds = [];
    },
    addPinnedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.pinnedAssetIds = [...state.pinnedAssetIds, action.payload];
    },
    removePinnedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.pinnedAssetIds = [
        ...state.pinnedAssetIds.filter((_id) => _id !== action.payload),
      ];
    },
    setSelectedAssetIds(state, action: PayloadAction<Asset["_id"][]>) {
      state.selectedAssetIds = [...action.payload];
    },
    clearAllSelectedAssetIds(state) {
      state.selectedAssetIds = [];
    },
    clearAllPinnedAssetIds(state) {
      state.pinnedAssetIds = [];
    },
    removeSoldAssetsFromStore(state) {
      for (const asset in state.assets) {
        if (state.assets[asset].status === "SOLD") delete state.assets[asset];
      }
    },
  },
});

export const fetchAllAssetData = () => async (
  dispatch: AppDispatch
) => {
  api.assets.getAll({
    onData: (data) => {
      dispatch(setAllAssets(data));
    },
    onComplete: () => dispatch(setAssetsLoading(false)),
  });
};

export const selectAssets = (state: STARFLEET_STORE) => state.assets.assets;

export const assetSerialNumberLookup = (state: STARFLEET_STORE) => {
  const assets = Object.values(state.assets.assets);
  return assets.reduce((serialNumberLookup, asset) => {
    serialNumberLookup[asset.serialNumber] = asset;
    return serialNumberLookup;
  }, {} as { [serialNumber: string]: Asset });
}

export const selectSelectedAssetIds = (state: STARFLEET_STORE) => state.assets.selectedAssetIds;

export const selectAssetContractHistoryLookup = (state: STARFLEET_STORE) => {
  return Object.values(state.contracts.contracts).reduce((contractByAssetLookup, contract) => {
    for (const assetDeliverable of contract.assetDeliverables) {
      if(contractByAssetLookup[assetDeliverable.asset]){
        contractByAssetLookup[assetDeliverable.asset] = 
        {
          ...contractByAssetLookup[assetDeliverable.asset], 
          currentContract: assetDeliverable.isActiveOnContract
            ? contract._id
            : contractByAssetLookup[assetDeliverable.asset].currentContract,
          contractHistory: [...contractByAssetLookup[assetDeliverable.asset].contractHistory, contract._id],
        };
      
      } else {

        contractByAssetLookup[assetDeliverable.asset] = 
          {
            currentContract: assetDeliverable.isActiveOnContract
              ? contract._id
              : null,
            contractHistory: [contract._id],
          };
      }
    }

    return contractByAssetLookup;
  }, {} as { [assetId: string]: { currentContract: Contract["_id"] | null, contractHistory: Contract["_id"][]} })
}
export const selectSelectedAssets = populateIds<Asset>('assets.selectedAssetIds', 'assets.assets');

export const selectCurrentAsset = (state: STARFLEET_STORE) => state.assets.assets[String(state.assets.currentAssetId)] as Asset;

export const selectAssetsLoading = (state: STARFLEET_STORE) => state.assets.assetsLoading;

export interface MasterUnitRecord {
  asset: string;
  branch: string;
  status: AssetStatus;
  client: string | null;
  projectNumber: string | null;
  startDate: MMDDYYYY | null;
  endDate: MMDDYYYY | null;
  billTo: string | null;
  shipTo: string | null;
  rentalRate: string | null;
  saleAmount: string | null,
  monthlyDepreciation: string | null,
  payback: string | null,
}

export const selectMasterUnitListData = (state: STARFLEET_STORE) => {
  const assets = selectAssets(state);
  const clients = selectClients(state);
  const branches = selectBranches(state);
  const loggedInUser = selectLoggedInUser(state) as UserData;

  const assetsOnContracts = Object.values(state.contracts.contracts).reduce((assetsOnContracts, contract) => {

    contract.assetDeliverables.forEach((deliverable) => {
      const assetInUserBranches = loggedInUser.branches.includes(assets[deliverable.asset]?.branch);
      const asset = assets[deliverable.asset]
      if (deliverable.isActiveOnContract && assetInUserBranches) {
        assetsOnContracts[deliverable.asset] = {
          asset: assets[deliverable.asset].serialNumber,
          branch: branches[assets[deliverable.asset].branch].name,
          status: assets[deliverable.asset].status,
          client: clients[contract.client]?.companyName,
          projectNumber: contract.projectNumber,
          startDate: toDDMMMYYYY(dateFromMMDDYYYY(contract.startDate)),
          endDate: contract.endDate && toDDMMMYYYY(dateFromMMDDYYYY(contract.endDate)),
          shipTo: displayAddress(contract.shipTo),
          billTo: displayAddress(contract.billTo),
          rentalRate: Boolean(deliverable.rentalRate.amount) ? `${fCurrency(deliverable.rentalRate?.amount)} ${deliverable.rentalRate?.currency}` : null,
          saleAmount: Boolean(deliverable.saleAmount.amount) ? `${fCurrency(deliverable.saleAmount?.amount)} ${deliverable.saleAmount?.currency}` : null,
          monthlyDepreciation: calcMonthlyDepreciation(asset).toFixed(2),
          payback: Boolean(deliverable.rentalRate) && asset.capitalCost.amount ? (asset.capitalCost.amount / deliverable.rentalRate.amount).toFixed(1) : null
        }
      }
    })
    return assetsOnContracts;
  },{} as { [assetId: string]: MasterUnitRecord })
  
  const masterUnitListData = Object.values(assets).reduce((mulData, asset) => {
      if (!assetOnContract(asset)) {
        mulData[asset._id] = {
          asset: asset.serialNumber,
          branch: branches[asset.branch].name,
          status: asset.status,
          client: null,
          projectNumber: null,
          startDate: null,
          endDate: null,
          shipTo: null,
          billTo: null,
          rentalRate: null,
          saleAmount: null,
          monthlyDepreciation: calcMonthlyDepreciation(asset).toFixed(2),
          payback: null
        }
      }
    return mulData;
  }, assetsOnContracts)

  function assetOnContract(asset: Asset){
    return Boolean(assetsOnContracts[asset._id])
  }

  return masterUnitListData;
}
export const selectFeaturedAssets = (state: STARFLEET_STORE) => {
  return Object.values(state.assets.assets).filter(addToFeatured).slice(0,3);
};
function addToFeatured(asset: Asset){
  return (
    asset.status === "AVAILABLE" &&
    Number(asset.hrs || 0) < 10 &&
    asset?.photos?.length
  );
}

export const selectAvailableAssets = (state: STARFLEET_STORE) => {
  return Object.values(state.assets.assets).filter(asset => asset.status === "AVAILABLE")
};

export const selectLeasedAssets = (state: STARFLEET_STORE) => {
  return Object.values(state.assets.assets).filter(asset => asset.status === "ON LEASE")
};

export const selectAssetsByStatus = (state: STARFLEET_STORE) => {
  return Object.values(state.assets.assets).reduce((assetsByStatus: Record<AssetStatus, Asset[]>, asset) => {
   assetsByStatus[asset.status] ? assetsByStatus[asset.status].push(asset) : assetsByStatus[asset.status] = [asset]
    return assetsByStatus
  }, {
      "AVAILABLE": [],
      "RESERVED": [],
      "ON LEASE": [],
      "SOLD": [],
      "SOLD PENDING DELIVERY": [],
      "MAINTENANCE NEEDED": [],
      "NOT RENTABLE": [],
      "IN MAINTENANCE": [],
      "INSPECT": [],
    })
};

export const activeStatuses = ["SOLD PENDING DELIVERY", "ON LEASE"];
export const statusesToExclude = ["SOLD"]

export function assetIsInSelectedBranches(asset: Asset, selectedBranchIds: string[]) {
  return selectedBranchIds.length
    ? selectedBranchIds.includes(asset.branch)
    : true;
}

export function getBranchAssetsNotInProduction(assets: Asset[], selectedBranchIds: string[]) {
  return assets.filter(asset => selectedBranchIds.length ? (selectedBranchIds.includes(asset.branch) && !asset.inProduction) : true)
}


export const selectActiveAssets = (state: STARFLEET_STORE) => {
  return Object.values(state.assets.assets).filter(asset => !statusesToExclude.includes(asset.status))
}


export const {
    setAssetsLoading,
    setAllAssets,
    setCurrentAssetId,
    clearCurrentAssetId,
    addSelectedAssetId,
    removeSelectedAssetId,
    clearAllSelectedAssets,
    clearAllPinnedAssets,
    addPinnedAssetId,
    removePinnedAssetId,
    setSelectedAssetIds,
    clearAllSelectedAssetIds,
    clearAllPinnedAssetIds,
    setOneAsset,
    setManyAssets,
    removeSoldAssetsFromStore
} = assetSlice.actions;

export default assetSlice.reducer;
