import Cancel from "@mui/icons-material/Cancel";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import { CircularProgress, IconButton, SxProps } from "@mui/material";
import Blade from "components/Blade";
import { Formik, FormikHelpers, FormikProps } from "formik";
import _ from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { ClientContact } from "store/models/ClientContact";
import ClientContactForm from "./ClientContactForm";
import ClientContactView from "./ClientContactView";
import { getFullName } from "utils/util";
import { contactValidationSchema } from "utils/formikAPI";


interface Props {
    open: boolean;
    handleClose: () => void;
    clientContact: ClientContact;
    handleSubmit: (values: ClientContact, formikHelpers: FormikHelpers<ClientContact>) => void
    isNew?: Boolean
    bladeStyle?: SxProps
    clientId?: string
}

const ClientContactBlade: FC<Props> = ({
  open,
  handleClose,
  clientContact,
  handleSubmit,
  isNew,
  bladeStyle,
  clientId
}) => {

  const [mode, changeMode] = useState<"view" | "edit" | "new">(isNew ? "new" : "view");

  const closeBlade = () => {
    changeMode("view")
    handleClose()
  }

  const getClientContactContents = () => {
    if (mode === "view") {
      return <ClientContactView clientContact={clientContact} />;
    }

    return <ClientContactForm clientId={clientId} mode={mode} />;
  };

  const getBladeActions = useCallback((formikProps: FormikProps<ClientContact> ) => {
    const {submitForm, isSubmitting, values, resetForm} = formikProps
    const bladeActions: JSX.Element[] = []
    mode === "view" &&
      bladeActions.push(
        <IconButton
          key="edit"
          onClick={() => changeMode("edit")}
          color="primary"
          disabled={false}
        >
          <Edit />
        </IconButton>
      );
    
      (mode === "new" ||  mode === "edit") &&
        bladeActions.push(
          <IconButton
            color="primary"
            onClick={submitForm}
            disabled={_.isEqual(values, clientContact) || isSubmitting}
            data-testid="submitClientForm"
            key="submitClientForm"
          >
            {isSubmitting ? <CircularProgress size={24} /> : <Save />}
          </IconButton>
        );
      mode === "edit" &&
        bladeActions.push(
          <IconButton
            onClick={() => {
              changeMode("view");
              resetForm();
            }}
            key="cancel"
          >
            <Cancel />
          </IconButton>
        );
        return bladeActions
  },[mode])
  const getBladeTitle = () => {
    switch (mode) {
      case "view":
        return
      case "edit":
        return `Edit ${getFullName(clientContact)}`
      case "new":
        return "Create New Contact"
    }
  }
  useEffect(() => {
    !isNew && changeMode("view")
  },[clientContact._id])





  return (
    <Formik
      initialValues={clientId ? {...clientContact, currentClient: clientId} : clientContact}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={contactValidationSchema}
    >
      {(formikHelpers) => {
        return (
          <Blade open={open} changeOpen={closeBlade} actions={getBladeActions(formikHelpers)} title={getBladeTitle()} bladeStyle={{...bladeStyle}}>
            {getClientContactContents()}
          </Blade>
        );
      }}
    </Formik>
  );
};

export default ClientContactBlade;

