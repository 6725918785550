import { forwardRef } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import { IconifyIcon, Icon } from '@iconify/react';
//

// ----------------------------------------------------------------------

export type IconifyProps = IconifyIcon | string;

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps;
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...other}
  />
));

export default Iconify;
