import React from "react";
import ThemeProvider from './theme';
import ThemeColorPresets from './components/ThemeColorPresets';
import GlobalStyles from './theme/globalStyles';
import { ProgressBarStyle } from './components/ProgressBar';
import { ChartStyle } from './components/chart';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import 'react-image-lightbox/style.css';
import 'simplebar/dist/simplebar.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Router from "routes/Router";
import { Wrapper as ReactGoogleMapsWrapper } from "@googlemaps/react-wrapper";
import { ErrorBoundary } from "react-error-boundary"
import Page500 from "routes/Page500";

// // Make sure that empty string or other invalid transport companies are removed or not created when/after running this. Otherwise invalid transport companies will be assigned
// db.getCollection("noncontractmovements").distinct("transportCompanyName").forEach((transportCompanyName) => {
//   print(transportCompanyName)
//   db.getCollection("transportcompanies").insertOne({_id: new ObjectId(), name: transportCompanyName, phoneNumber: "", address: null})
// })

// db.getCollection("contracts").aggregate([{$project: {movements: 1}}, {$unwind: "$movements"}, {$project: {"transportCompanyName": "$movements.transportCompanyName"}}]).forEach(({transportCompanyName}) => {
    
//   const company = db.getCollection("transportcompanies").findOne({name: transportCompanyName})
  
//   if (!company) {
//       print(transportCompanyName)
//       db.getCollection("transportcompanies").insertOne({_id: new ObjectId(), name: transportCompanyName, phoneNumber: "", address: null})
//   }

// })

// db.getCollection("contracts").aggregate([{$project: {movements: 1, _id: 1}}, 
//   {$unwind: "$movements"}, 
//   {$project: {"transportCompanyName": "$movements.transportCompanyName", movementId: "$movements._id", contractId: "$_id"}}
//   ]).forEach(({transportCompanyName, movementId, contractId}) => {
//       const transportCompany = db.getCollection("transportcompanies").findOne({name: transportCompanyName})
//               db.getCollection("contracts").updateOne(
//               {
//                   _id: contractId,
//                   "movements._id": movementId
//               },
//               {
//                   $set: {
//                       "movements.$.transportCompany": transportCompany._id
//                   }
//               }
//           );
          
//           print(movementId)
//   })

// db.getCollection("noncontractmovements").find({}).forEach(({_id, transportCompanyName}) => {
//   const transportCompany = db.getCollection("transportcompanies").findOne({name: transportCompanyName})
//   db.getCollection("noncontractmovements").updateOne({_id}, {$set: {_id, transportCompany: transportCompany._id  }})
//   print(transportCompanyName)
// }) 
  

const App: React.FC = () => {

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <MotionLazyContainer>
          <ReactGoogleMapsWrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!} libraries={["places"]}>
            <GlobalStyles />
            <ChartStyle />
            <ProgressBarStyle />
            <ErrorBoundary FallbackComponent={Page500}>
              <Router />
            </ErrorBoundary>
          </ReactGoogleMapsWrapper> 
        </MotionLazyContainer>
      </ThemeColorPresets>
    </ThemeProvider>
  );
};

export default App;
