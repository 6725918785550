import {
  Button,
  List,
  ListItem,
  ListItemButton,
  TextField,
  ListItemText,
  Card,
  Typography,
  darken,
  lighten,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FilterType } from "react-table";

interface Props {
  column: {
    filterValue: string[];
    filteredRows: any[];
    setFilter: Function;
    preFilteredRows: { values: any }[];
    id: string;
    default: string;
    alwaysInclude: string[];
    optionNameParser?: Function;
    optionsSort?: (a: any, b: any) => number;
  };
  state: { filters: { id: string; value: any[] }[] };
}

const ExcelColumnFilter: React.FC<Props & FilterType<any>> = (props) => {
  const {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    alwaysInclude = [],
    optionNameParser = (value: any) => value,
    optionsSort,
  } = props.column;
  const { state } = props;

  useEffect(() => {
    setFilter(filterValue);
  }, []);

  const currentFilter =
    state?.filters.find((filter) => filter.id === id)?.value || [];

  const [optionsFilterValue, changeOptionsFilterValue] = useState("");

  const addOrRemoveFromFilter = (filterValue: string) => {
    let newFilter: string[] = [];
    if (filterIsSelected(filterValue)) {
      // remove from filter
      newFilter = currentFilter.filter((filter) => filter !== filterValue);
    } else {
      // add to filter
      newFilter = [...currentFilter, filterValue];
    }
    setFilter(newFilter);
  };

  const options = React.useMemo(() => {
    const options: string[] = [];

    for (const row of preFilteredRows) {
      isFalsyAndNotBoolean(row) && options.push(String(row.values[id]));
    }

    function isFalsyAndNotBoolean(row: { values: any }) {
      return row.values[id] || typeof row.values[id] === "boolean";
    }

    for (const option of alwaysInclude) {
      options.push(String(option));
    }
    return _.uniq([...options].map((option) => String(option)));
  }, []);

  const optionsList = () => {
    function optionsReducer(acc: any, curr: any, index: number) {
      // Handle when displayed value (aka "title") is different than the value from the row object
      // Most commonly used when bool value needs to be more human readable
      const value = typeof curr === "string" ? curr : curr.value;
      const title = typeof curr === "string" ? curr : curr.title;

      if (
        optionNameParser(title)
          .toLocaleLowerCase()
          .includes(optionsFilterValue.toLocaleLowerCase())
      ) {
        acc.push(
          <ListItem
            disablePadding
            key={index}
            onClick={() => addOrRemoveFromFilter(value)}
          >
            <ListItemButton
              sx={(theme) => {
                const highlight =
                  theme.palette.mode === "light"
                    ? darken(theme.palette.background.neutral, 0.2)
                    : lighten(theme.palette.background.neutral, 0.2);
                return {
                  backgroundColor: filterIsSelected(title) ? highlight : "",
                  "&:hover": {
                    backgroundColor: highlight,
                  },
                };
              }}
            >
              <ListItemText
                sx={(theme) => {
                  return {
                    ".MuiListItemText-primary": {
                      fontSize: theme.typography.fontSize,
                    },
                  };
                }}
                primary={optionNameParser(title)}
              />
            </ListItemButton>
          </ListItem>
        );
      }
      return acc;
    }

    // Apply custom sort form user or apply default sort
    if (optionsSort) {
      options.sort(optionsSort);
    } else {
      options.sort();
    }

    const reduced = options.reduce(optionsReducer, [] as any);

    return reduced.length ? (
      reduced
    ) : (
      <ListItem>
        <Typography
          sx={{ textAlign: "center", width: "100%" }}
          variant="caption"
        >
          No Options
        </Typography>
      </ListItem>
    );
  };

  const filterIsSelected = (filterValue: string) =>
    !!state?.filters
      .find((filter) => filter.id === id)
      ?.value.includes(filterValue);

  return (
    <Card
      sx={{
        padding: 1,
        borderRadius: 1,
        zIndex: 10,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TextField
        sx={{ width: 300 }}
        autoFocus
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Search..."
        value={optionsFilterValue}
        onChange={(e) => changeOptionsFilterValue(e.currentTarget.value)}
      />

      <Scrollbar sx={{ maxHeight: 200 }}>
        <List>{optionsList()}</List>
      </Scrollbar>
      <Button
        onClick={() => setFilter(undefined)}
        variant="outlined"
        fullWidth
        size="small"
        disabled={!filterValue}
      >
        Clear
      </Button>
    </Card>
  );
};

export default ExcelColumnFilter;
