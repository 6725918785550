import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "store/api";
import { IContractHistory } from "store/models/ContractHistory";
import { selectCurrentContract } from "store/slices/contractSlice";
import { selectUsers } from "store/slices/userSlice";
import { getUserFullName, toDDMMMYYYY } from "utils/util";

interface Props {}

const ContractHistroy: FC<Props> = () => {
  const [contractHistory, setContractHistory] = useState<IContractHistory>();
  const currentContract = useSelector(selectCurrentContract);
  const [isSubmitting, setSubmitting] = useState(true);
  const users = useSelector(selectUsers);


  useEffect(() => {
    currentContract && api.contracts.getContractHistory(currentContract._id, {
      onData: (data) => {
        data ? setContractHistory({ ...data, updateLog: data.updateLog.reverse()}) : setContractHistory(undefined);
        setSubmitting(false);
      },
      onError: () => setSubmitting(false),
    })
  }, [currentContract?._id, currentContract?.status, currentContract?.assetDeliverables])

  return isSubmitting || !currentContract ? (
    <Box sx={{ display: "flex", justifyContent: "center", with: "100%" }}>
      <CircularProgress />
    </Box>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {contractHistory && contractHistory.updateLog ? (
        contractHistory.updateLog.map((log) => {
          const updateDescriptionItems = log.updateDescription.split(/(?:\r\n|\r|\n)/g);
          return (
            <Card sx={{ my: 1 }}>
              <CardContent
                sx={(theme) => ({
                  a: {
                    color: theme.palette.primary.main,
                  },
                })}
              >
                <Typography
                  component='ul'
                  ml={2}
                  // inner html is set to enable anchor tags
                  // logs cannot be directly updated by anyone at run time without direct access to the DB
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: updateDescriptionItems.reduce(turnDescritionsIntoBulletPoints, "")}}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mt: 2, ml: 2 }}>
                    {toDDMMMYYYY(log.updatedDate)}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    {getUserFullName(users[log.updatedBy])}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Card>
          <CardContent>
            <Typography>No Changes Yet!</Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
}

function turnDescritionsIntoBulletPoints(acc: string, line: string) {
  if (line) {
    return acc + `<li>${line}</li></br>`
  } else {
    return acc
  }
}

export default ContractHistroy;
