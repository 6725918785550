import { TextField, Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FilterProps } from 'react-table';

const TextFilter = <T extends Record<string, unknown>>({ column }: FilterProps<T>) => {

    const { id, filterValue, setFilter, render } = column

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value || undefined)
    }
  
    return (
      <Box
        sx={(theme) => ({
          boxShadow: theme.customShadows.z12,
          backgroundColor: "inherit",
          p: 1,
        })}
        id={id}
      >
        <TextField
          label={<Box>{render("Header")}</Box>}
          style={{ maxWidth: "100%", minWidth: 240 }}
          InputLabelProps={{ htmlFor: id }}
          value={filterValue || ""}
          variant="outlined"
          size="small"
          onChange={handleChange}
          autoFocus
        />
      </Box>
    );
  }
export default TextFilter;