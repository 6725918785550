import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import { isPast } from "date-fns";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useGetWorkOrdersByContractIdQuery, WorkOrdersByContractId } from "store/services/workOrder";
import { selectCurrentContract } from "store/slices/contractSlice";
import { setCurrentWorkOrderId } from "store/slices/maintenanceSlice";
import { useAppDispatch } from "store/store";
import { fCurrency } from "utils/formatNumber";
import { toDDMMMYYYY } from "utils/util";
interface Props {}

const ContractWorkOrders: FC<Props> = () => {
  const currentContract = useSelector(selectCurrentContract);

  const { data: contractWorkOrders = [], isLoading } =
    useGetWorkOrdersByContractIdQuery(
      { contractId: currentContract?._id || "" },
      { skip: !currentContract }
    );

  return (
    <LoadingComponent
      isLoading={isLoading}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {contractWorkOrders.length ? (
        contractWorkOrders.map((workOrder) => (
          <WorkOrderCard workOrder={workOrder} />
        ))
      ) : (
        <Box sx={{display: "flex", justifyContent: "center"}}><Typography variant="body1">No work orders found</Typography></Box>
      )}
    </LoadingComponent>
  );
};

export default ContractWorkOrders;

export const WorkOrderCard: FC<{ workOrder: WorkOrdersByContractId }> = ({
  workOrder,
}) => {
  const asset = workOrder.contract.assetDeliverables.find(
    (deliverable) => deliverable.asset._id === workOrder.asset
  )?.asset;

  const dispatch = useAppDispatch()

  const handleWorkOrderClick = () => {
    dispatch(setCurrentWorkOrderId(workOrder._id));
  }

  return (
    <Card>
      <CardContent
        onClick={() => handleWorkOrderClick()}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6">
              {`#${workOrder.number}: ${
                workOrder.title || workOrder.inspectionType
              }`}
            </Typography>
            <Typography variant="body1">{workOrder.description}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="body2">{workOrder.status}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="body2"
            sx={{ flex: 1 }}
          >
            Created: {toDDMMMYYYY(workOrder.createdOn)}
          </Typography>
          <Typography
            variant="body2"
            color={isPast(new Date(workOrder.dueDate)) ? "error" : "primary"}
          >
            Due: {toDDMMMYYYY(workOrder.dueDate)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {asset?.serialNumber} - {asset?.sizeCode}
          </Typography>
        </Box>
        {!workOrder.inspection && (
          <Box sx={{ display: "flex" }}>
            <Typography variant="body2" sx={{ flex: 1 }}>
              Hours -{" "}
              {workOrder.actualHoursToComplete ||
                `${workOrder.estimatedHours} (EST)`}
            </Typography>
            <Typography variant="body2">
              {" "}
              Materials -{" "}
              {workOrder.actualMaterialsToComplete?.amount
                ? fCurrency(workOrder.actualMaterialsToComplete?.amount)
                : `${fCurrency(workOrder.estimatedMaterials?.amount)} (EST)`}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
