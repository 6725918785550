import  Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import {
  Badge,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Step,
  Stepper,
  StepButton,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Iconify from "components/Iconify";
import AsyncButton from "components/upload/AsyncButton";
import _ from "lodash";
import { Dispatch, SetStateAction, FC, useState } from "react";
import { IInspection, IChecklistItem, IChecklistResultOption } from "store/models/Inspection";
import { useAppDispatch } from "store/store";
import { titleCase } from "utils/util";
import { setGlobalMessage } from "store/slices/systemSlice";
import { useSelector } from "react-redux";
import MaintenanceTable from "features/maintenance/MaintenanceTable";
import WorkOrderViewEditBlade from "features/maintenance/blades/WorkOrderViewEditBlade";
import FailedListItemTable from "features/maintenance/FailedListItemTable";
import CheckListItemViewEditBlade from "features/maintenance/blades/CheckListItemViewEditBlade";
import pluralize from "pluralize";
import AddPhotoAlternate from "@mui/icons-material/AddPhotoAlternate";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { selectAdditionalCheckListItems } from "store/slices/configSlice";
import { WebWOQueryResponse, useGetGeneratedWorkOrdersByInspectionIdQuery, useUpdateWorkOrderMutation } from "store/services/workOrder";
import { useGenerateInspectionWorkOrdersMutation, useUpdateCheckListItemMutation, useUpdateInspectionMutation } from "store/services/inspection";
import { WorkOrderStatus } from "store/models/WorkOrder";

interface ItemProps {
  item: IChecklistItem;
  handleItemToggle: (item: IChecklistItem, value: IChecklistResultOption) => void;
  setSelectedListItemId?: Dispatch<SetStateAction<string | undefined>>;
}

const InspectionItem: FC<ItemProps> = ({ item, handleItemToggle, setSelectedListItemId }) => {
  const handlePass = () => {
    handleItemToggle(item, "Pass")
    setSelectedListItemId && setSelectedListItemId(item._id)
  }

  const handleFail = () => {
    handleItemToggle(item, "Fail")
    setSelectedListItemId && setSelectedListItemId(item._id)
  }
  
  const handleNotApplicable = () => {
    handleItemToggle(item, "N/A")
    setSelectedListItemId && setSelectedListItemId(undefined)
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: 400,
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
      }}
    >
      <Typography>{item.taskName}</Typography>
      <Box marginRight={2} display="flex">
        <Button
          endIcon={(
            <Badge badgeContent={item.result === "Pass" ? item.photos.length : 0} color="info">
              <AddPhotoAlternate />
            </Badge>
          )}
          onClick={handlePass}
          variant={item.result === "Pass" ? "contained" : "outlined"}
          sx={{
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          fullWidth
        >
          Pass
        </Button>
        <Button
          variant={item.result === "Fail" ? "contained" : "outlined"}
          color="error"
          endIcon={<OpenInNew />}
          onClick={handleFail}
          sx={{ borderRadius: 0 }}
          fullWidth
        >
          Fail
        </Button>
        <Button
          variant={item.result === "N/A" ? "contained" : "outlined"}
          onClick={handleNotApplicable}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
          fullWidth
        >
          N/A
        </Button>
      </Box>
    </Box>
  );
};

interface InspectionModalProps {
  inspection: IInspection;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  workOrder: WebWOQueryResponse;
}

const InspectionModal: FC<InspectionModalProps> = ({
  inspection,
  open,
  setOpen,
  workOrder,
}) => {
  const inspectionCategories = _.uniq(inspection.inspectionChecklist.map((item) => item.category));
  const steps = inspectionCategories.map((category) => titleCase(category));
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useAppDispatch();
  const [updateInspection] = useUpdateInspectionMutation()
  const [updateCheckListItem] = useUpdateCheckListItemMutation()
  const [updateWorkOrder] = useUpdateWorkOrderMutation()
  const [selectedListItemId, setSelectedListItemId] = useState<string>();
  const failedCheckListItems = inspection.inspectionChecklist.filter(({ result }) => result === "Fail");
  const issuesFound = Boolean(failedCheckListItems.length)
  const selectedListItem = inspection.inspectionChecklist.find((listItem) => selectedListItemId === listItem._id)
  const {data: generatedWorkOrders = [], refetch} = useGetGeneratedWorkOrdersByInspectionIdQuery({inspectionId: inspection._id}, {skip: !inspection._id || !open})
  const [generateInspectionWorkOrders, {isLoading: isGeneratingWorkOrders}] = useGenerateInspectionWorkOrdersMutation()
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WebWOQueryResponse>();
  const [isAddingListItem, setIsAddingListItem] = useState(false);
  const additionalCheckListItems = useSelector(selectAdditionalCheckListItems);
  const [standardServiceListItem] = additionalCheckListItems;
  const standardServiceAdded = inspection.inspectionChecklist.some((listItem) => listItem.taskName === standardServiceListItem.taskName)

  const addAdditionalChecListItem = (checkListItem: IChecklistItem) => {
    setIsAddingListItem(true);
    updateInspection({ ...inspection, inspectionChecklist: [...inspection.inspectionChecklist, checkListItem] }).unwrap().then(() => {
      setIsAddingListItem(false);
    })
  }

  if (issuesFound){
    steps.push("Repair Estimates")
  }
  if (generatedWorkOrders.length) {
    steps.push("Work Orders")
  }
  const isLastStep = Boolean(currentStep === steps.length -1)

  const closeModal = () => setOpen(false);

  const handleItemToggle = (
    item: IChecklistItem,
    result: IChecklistResultOption
  ) => {
    updateCheckListItem({inspectionId: inspection._id, checklistItem: { ...item, result }});
  };

  const getCurrentStep = () => {
    if (steps[currentStep] === "Repair Estimates") {
      return (
        <Box
          key={currentStep}
          m="auto"
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Failed Check List Items
          </Typography>
          <FailedListItemTable 
            checkListItems={failedCheckListItems}
            onRowClick={(checkListItem) => setSelectedListItemId(checkListItem._id)}
            generatedWorkOrders={generatedWorkOrders}
          />
          {selectedListItem && (
            <CheckListItemViewEditBlade 
              open={Boolean(selectedListItem)}
              checkListItem={selectedListItem}
              inspection={inspection}
              onClose={() => setSelectedListItemId(undefined)}
            />
          )}
        </Box>
      );
    } else if (steps[currentStep] === "Work Orders") {
      return (
        <Box sx={{ m: "auto" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Inspection Generated Work Orders
          </Typography>
          <MaintenanceTable
            workOrders={generatedWorkOrders}
            onRowClick={setSelectedWorkOrder}
            tableId="inspectionWorkOrdersTable"
          />
          {selectedWorkOrder && (
            <WorkOrderViewEditBlade
              open={Boolean(selectedWorkOrder)}
              workOrder={selectedWorkOrder}
              onClose={() => setSelectedWorkOrder(undefined)}
            />
          )}
        </Box>
      );
    } else {
      const category = inspectionCategories[currentStep];
      return (
        <Box sx={{ width: "100%", height: "100%" }} key={currentStep}>
          <Typography variant="h5" sx={{ my: 2 }}>
            {category}
          </Typography>
          <Box
            key={currentStep}
            sx={{
              display: "grid",
              py: 5,
              gridTemplateColumns: {
                lg: "auto auto auto",
                md: "auto auto",
                xs: "auto",
              },
              gap: 2,
            }}
          >
            {inspection.inspectionChecklist
              .filter((inspection) => inspection.category === category)
              .map((item, index) => (
                <InspectionItem
                  key={`checkListItem - ${item.category} - ${index}`}
                  item={item}
                  handleItemToggle={handleItemToggle}
                  setSelectedListItemId={setSelectedListItemId}
                />
              ))}
          </Box>
          {selectedListItem && (
            <CheckListItemViewEditBlade 
              open={Boolean(selectedListItem)}
              checkListItem={selectedListItem}
              inspection={inspection}
              onClose={() => setSelectedListItemId(undefined)}
              imagesOnly={selectedListItem.result === "Pass"}
            />
          )}
        </Box>
      );
    }
  }

  const completeInspection = () => new Promise((reslove, reject) => {
    updateWorkOrder({...workOrder, status: WorkOrderStatus.COMPLETE}).unwrap().then((updatedWorkOrder) => {
      dispatch(setGlobalMessage({
        show: true,
        messageText: "Successfully completed inspection",
        severity: "success",
      }));
      reslove(updatedWorkOrder);
    }).catch(reject)
  })

  const workOrdersCanBeGenerated = () => {
    const allFailedListItemsAreValid = failedCheckListItems.every(hasRequiredStandardWorkOrderProps);
    return allFailedListItemsAreValid;

    function hasRequiredStandardWorkOrderProps(checkListItem: IChecklistItem) {
      return Boolean(
        checkListItem.estimatedMaterials.amount >= 0 &&
        checkListItem.estimatedHours >= 0 &&
        checkListItem.dueDate &&
        checkListItem.title &&
        checkListItem.workOrderCategory &&
        checkListItem.workOrderSubcategory && 
        checkListItem.priority 
      );
    }
  }

  const generateWorkOrders = () => {
    if(!workOrdersCanBeGenerated()){
      dispatch(setGlobalMessage({
        show: true,
        messageText: "1 or more Check list Items are missing required fields",
        severity: "warning",
      }));
      return;
    }

    generateInspectionWorkOrders({inspectionId: inspection._id}).unwrap().then(({workOrders}) => {
      refetch()
      dispatch(setGlobalMessage({
        show: true,
        messageText: `Successfully generated ${workOrders.length} Work ${pluralize("Order", workOrders.length)}`,
        severity: "success",
      }));
    })
  }
  

  function showCompleteButton () {
    return steps[currentStep] === "Repair Estimates" && !generatedWorkOrders.length || isLastStep && steps[currentStep] !== "Work Orders";
  }

  return (
    <Dialog open={open} onClose={closeModal} fullScreen>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Inspection Work Order</Typography>
        <Box>
          <Button
            onClick={() => addAdditionalChecListItem(standardServiceListItem)}
            variant="contained"
            color="primary"
            sx={{ mr: 3, width: 200 }}
            disabled={isAddingListItem || standardServiceAdded}
          >
            {isAddingListItem ? <CircularProgress size={24} /> : "Add Standard Service"}
          </Button>
          {workOrder.status === "COMPLETE" && (
            <Button
              onClick={generateWorkOrders}
              variant="contained"
              color="primary"
              sx={{ mr: 3, width: 200 }}
              disabled={isGeneratingWorkOrders}
            >
              {isGeneratingWorkOrders ? <CircularProgress size={24} /> : "Generate Work Orders"}
            </Button>
          )}
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {getCurrentStep()}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => setCurrentStep((step) => step - 1)}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            disabled={currentStep === 0}
            sx={{ minWidth: "160px" }}
          >
            Back
          </Button>
          <Stepper
            nonLinear
            activeStep={currentStep}
            sx={{ width: "80%", mx: 3 }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={currentStep === steps.length}>
                <StepButton
                  color="inherit"
                  onClick={() => setCurrentStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {
            showCompleteButton() ? (
              <AsyncButton
                variant="outlined"
                size="small"
                onClick={completeInspection}
                startIcon={<Check />}
                sx={{ minWidth: "160px" }}
              >
                Complete
              </AsyncButton>
            ) : (
              isLastStep ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={closeModal}
                  startIcon={<Check />}
                  sx={{ minWidth: "160px" }}
                >
                  Done
                </Button>
                
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setCurrentStep((step) => step + 1)}
                  startIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                  sx={{ minWidth: "160px" }}
                >
                  Next
                </Button>
              )
            )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InspectionModal;
