import React, { Fragment, useMemo, useState, useEffect, useCallback, MouseEvent } from "react";
import {
  Box,
  IconButton,
  Card,
  TablePagination as MuiTablePagination,
  TextField as MuiTextField,
  Typography,
  BoxProps,
  TableContainer as MUITableContainer,
} from "@mui/material";
import Menu from "@mui/icons-material/Menu";
import {
  ActionType,
  Column,
  Row,
  TableState,
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useGroupBy,
  useExpanded,
  usePagination,
  useRowSelect,
  useFlexLayout,
  HeaderPropGetter,
  FilterTypes,
  Filters,
  useColumnOrder,
  ColumnInstance,
} from "react-table";
import RowExpansion from "./RowExpansion";
import CellContent from "./cell-contents/CellContent";
import TableActionPopover from "./TableActionPopover";
import useRowSubComponent from "./useRowSubComponent";
import useRowSelection from "./useRowSelection";
import pluralize from "pluralize";
import ColumnHeader from "./ColumnHeader";
import { highestToLowest } from "utils/formatUtils";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import { isAfter, isBefore, isEqual } from "date-fns";
import { isFalsyString } from "utils/util";
import TableLoading from "./TableLoading";

const TableContainer = styled(MUITableContainer)(({theme}) => ({
  width: "100%",
  paddingRight: 12,
  paddingLeft: 12,
  paddingBottom: 0,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("xl")]: {
    px: 4,
  }
}));

const TablePagination = styled(MuiTablePagination)<{component: string}>(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  component: "div",
  [theme.breakpoints.down("xl")]: {
    px: 4,
  },
}))

const GlobalFilterSearch = styled(MuiTextField)({});

const TableTitle = styled(Typography)(({theme}) => ({
  display: "grid",
  alignItems: "center",
  columnGap: 12,
  gridAutoFlow: "column",
  whiteSpace: "nowrap",
  marginLeft: 4,
  [theme.breakpoints.down("xl")]: {
    columnGap: 4,
  },
}));

const OpenPopoverIcon = styled(Menu)({
  cursor: "pointer",
  marginLeft: -3
});

const TableRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "clickable",
})<BoxProps & { clickable?: boolean }>(({ clickable = false }) => ({
  cursor: clickable ? "pointer" : "",
}));

const TableHeader = styled(Box)(({theme}) => ({
  userSelect: "none",
  marginTop: 8,
  borderRadius: 8,
  whiteSpace: "nowrap",
  fontSize: theme.typography.fontSize,
  [theme.breakpoints.down("xl")]: {
    marginTop: 0,
  },
}));

const HeaderGroup = styled(Box)({});
const TableBody = styled(Box)({});
const TableCell = styled(Box)(({theme}) => ({
  padding: 16,
  fontWeight: 400,
  fontSize: theme.typography.fontSize,
  height: 52,
  [theme.breakpoints.down("xl")]: {
    padding: 12,
  },
}));

const TopActionGrid = styled(Box)(({theme}) => ({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  padding: 24,
  display: "grid",
  gridAutoFlow: "column",
  gridTemplateColumns: "min-content min-content auto max-content",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("xl")]: {
    padding: 12,
  },
}));

const TableActions = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  justifyContent: "flex-end",
  columnGap: 16,
  marginLeft: 16,
});

export interface TableActionProps<T extends object = any> {
  selectedRows: Row<T>[];
  filteredRows: Row<T>[];
  toggleAllRowsSelected: (set?: boolean) => void;
  currentFilters: Filters<T>
}

interface Props<T extends object = any> {
  id: string;
  columns: Column<T>[];
  data: T[];
  tableTitle?: string;
  hiddenColumns?: string[];
  optionalColumns?: string[];
  autoResetHiddenColumns?: boolean;
  tableActions?: (data: TableActionProps<T>) => JSX.Element[]; 
  stateReducer?: (
    newState: TableState<any>,
    action: ActionType,
    prevState: TableState<any>
  ) => typeof newState;
  useControlledState?: (state: TableState<any>) => typeof state;
  defaultColumn?: Partial<Column<any>>;
  initialFilters?: { id: string; value: any }[];
  manualFilters?: boolean;
  disableFilters?: boolean;
  autoResetFilters?: boolean;
  initiaxllobalFilter?: any;
  globalFilter?: string;
  manuaxllobalFilter?: boolean;
  disableGlobalFilter?: boolean;
  autoResetGlobalFilter?: boolean;
  initialSortBy?: { id: string; desc: boolean }[];
  manualSortBy?: boolean;
  disableSortBy?: boolean;
  disableMultiSort?: boolean;
  maxMultiSortColCount?: number;
  autoResetSortBy?: boolean;
  initiaxlroupBy?: string[];
  manuaxlroupBy?: boolean;
  disableGroupBy?: boolean;
  initialExpandedRows?: Record<string, boolean>;
  manualExpandedKey?: string;
  expandSubRows?: boolean;
  autoResetExpanded?: boolean;
  initialPageSize?: number;
  initialPageIndex?: number;
  initialPageCount?: number;
  manualPagination?: boolean;
  autoResetPage?: boolean;
  paginateExpandedRows?: boolean;
  initialSelectedRowIds?: Record<string, boolean>;
  manualRowSelectedKey?: string;
  autoResetSelectedRows?: boolean;
  rowSubComponent?: (row: Row<any>) => JSX.Element;
  enableRowSelection?: boolean;
  onRowClick?: (row: Row<any>, event: React.MouseEvent<HTMLDivElement>) => void;
  showGlobalFilter?: boolean;
  getSubRows?:((originalRow: T, relativeIndex: number) => object[]) | undefined;
  onSelectedRowsChange?: (rows: Row<any>[]) => void;
  selectRowOnRowClick?: boolean;
  enableSingleRowSelection?: boolean;
  isLoading?: boolean;
}

const useReactTable: React.FC<Props> = ({
  id,
  columns,
  data,
  hiddenColumns = [],
  optionalColumns = [],
  autoResetHiddenColumns = false,
  tableActions = () => [],
  stateReducer = (newState) => newState,
  useControlledState = (state: TableState<any>) => state,
  defaultColumn,
  initialFilters = [],
  manualFilters = false,
  disableFilters = false,
  initiaxllobalFilter,
  autoResetFilters = false,
  globalFilter,
  manuaxllobalFilter = false,
  disableGlobalFilter = false,
  autoResetGlobalFilter = false,
  initialSortBy = [],
  manualSortBy = false,
  disableSortBy = false,
  disableMultiSort = false,
  maxMultiSortColCount = 3,
  autoResetSortBy = false,
  initiaxlroupBy = [],
  manuaxlroupBy = false,
  disableGroupBy = true,
  initialExpandedRows = {},
  manualExpandedKey,
  expandSubRows = true,
  autoResetExpanded = false,
  initialPageSize = Number(localStorage.getItem("pageSize")) || 10,
  initialPageIndex = 0,
  initialPageCount,
  manualPagination = false,
  autoResetPage = false,
  paginateExpandedRows = true,
  initialSelectedRowIds = {},
  manualRowSelectedKey = "isSelected",
  autoResetSelectedRows = false,
  enableRowSelection = false,
  rowSubComponent,
  tableTitle,
  onRowClick,
  showGlobalFilter = false,
  getSubRows = (row) => row?.subRows || [],
  onSelectedRowsChange = () => {},
  selectRowOnRowClick = false,
  enableSingleRowSelection = false,
  isLoading = false,
}: Props) => {

  const wordOrderInsensitiveSearch = <T extends Object>( rows: Array<Row<T>>, colunmIds: string[], filterValue: string ) => {
    const words = filterValue.split(" ").filter((word) => Boolean(word));
    const bestMatches = rows.reduce((matchRankings, row) => {
      let matchedWordCount = 0;
      words.forEach((word) => {
        colunmIds.forEach((columnId) => {
          if (caseInsensitiveMatch()) {
            matchedWordCount++;
          }
          
          function caseInsensitiveMatch(){
            return String(row.values[columnId]).toLowerCase()?.includes(word.toLocaleLowerCase());
          }
        });
      });

      if (matchedWordCount) {
        matchRankings[matchedWordCount] = Boolean(matchRankings[matchedWordCount])
          ? [...matchRankings[matchedWordCount], row]
          : [row];
      }

      return matchRankings;
    }, {} as { [matchedWordCount: string]: Array<Row<T>> });

    const prioritizedMatches = Object.keys(bestMatches).sort(highestToLowest);
    return prioritizedMatches.reduce(
      (filteredRows, numOfMatches) => {
        bestMatches[numOfMatches].forEach((row) => filteredRows.push(row))
        return filteredRows;
      },
      [] as Array<Row<T>>
    );
  }
  const partialMatch = <T extends Object>( rows: Array<Row<T>>, colunmIds: string[], filterValue: string ) => {
    return rows.filter((row) => String(row.values[colunmIds[0]]).toLowerCase()?.includes(filterValue.toLocaleLowerCase()))
  }

  function filterByDateRange<T extends Object>(rows: Array<Row<T>>, colunmIds: Array<string>, filterValue: [Date, Date]) {
    return rows.filter(row => {
      const rowValue = new Date(row.values[colunmIds[0]]);
      const minValue = filterValue[0];
      const maxValue = filterValue[1];
      if (bothDatesSupplied()) {
        return (
          (isAfter(rowValue, minValue) && isBefore(rowValue, maxValue)) ||
          isEqual(rowValue, minValue) ||
          isEqual(rowValue, maxValue)
        );
      } else {
        return (
          isAfter(rowValue, minValue) ||
          isBefore(rowValue, maxValue) ||
          isEqual(rowValue, minValue) ||
          isEqual(rowValue, maxValue)
        );
      }
  
      function bothDatesSupplied() {
        return filterValue[0] && filterValue[1]
      }
  
    });
  }

  const filterTypes: FilterTypes<any> = useMemo(() => ({
    "undefined": wordOrderInsensitiveSearch,
    "dateRange": filterByDateRange,
    "partialMatch": partialMatch,
    "multiSelect": <T extends Object>(
      rows: Row<T>[], columnIds: string[], filterValue: string[],
    ) =>
    // Filter only if filters are selected
    filterValue.length === 0
      ? rows
      : rows.filter((row) => {
        // id can be a complex selector such as "value.subValue" so we'll use lodash's get() to keep things simple
        const rowValue = _.get(row, `original.${columnIds}`);
        if(!rowValue){
          return Boolean(filterValue.find(filter => isFalsyString(filter)))
        }
        return filterValue.includes(String(rowValue));
      })
  }), []);
  const sortTypes = useMemo(() => ({
    "stringDateSort": (a: Row<any>, b: Row<any>, id: string) => {
      const aDate = new Date(a.original[id]);
      const bDate = new Date(b.original[id]);
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0
      }
    },
    "MMDDYYYYSort": (a: Row<any>, b: Row<any>, id: string) => {
      const [aMonth, aDay, aYear] = (a.original[id] as string).split("-");
      const [bMonth, bDay, bYear] = (b.original[id] as string).split("-");
      const aDate = new Date(Number(aYear), Number(aMonth) - 1, Number(aDay));
      const bDate = new Date(Number(bYear), Number(bMonth) - 1, Number(bDay));
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0
      }
    }
  }), []);
  const aggregations = useMemo(() => ({}), []);
  const defaultExpandedRows = useMemo(() => ({}), []);
  const dataMemo = useMemo(() => data, [data]);

  const {
    state,
    // columns: instanceColumns,
    allColumns,
    visibleColumns,
    headerGroups,
    // footerGroups,
    // headers,
    // flatHeaders,
    rows,
    preFilteredRows,
    filteredRows,
    // setFilter,
    setAllFilters,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    // flatRows,
    // totalColumnsWidth,
    // toggleHideColumn,
    setHiddenColumns,
    // toggleHideAllColumns,
    // getToggleHideAllColumnsProps,
    // preGroupedRows,
    // toggleGroupBy,
    // setGroupBy,
    // toggleRowExpanded,
    // toggleAllRowsExpanded,
    // isAllRowsExpanded,
    // getToggleAllRowsExpandedProps,
    page,
    // pageCount,
    gotoPage,
    setPageSize,
    // pageSize,
    // selectedFlatRows,
    setGlobalFilter,
    toggleAllRowsSelected,
    setColumnOrder
  } = useTable(
    {
      columns,
      data: dataMemo,
      manualFilters,
      initialState: {
        hiddenColumns,
        filters: initialFilters,
        globalFilter: initiaxllobalFilter,
        sortBy: initialSortBy,
        groupBy: initiaxlroupBy,
        expanded: initialExpandedRows || defaultExpandedRows,
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        selectedRowIds: initialSelectedRowIds,
      },
      autoResetHiddenColumns,
      stateReducer,
      useControlledState,
      defaultColumn,
      disableFilters,
      autoResetFilters,
      filterTypes,
      globalFilter,
      manuaxllobalFilter,
      disableGlobalFilter,
      autoResetGlobalFilter,
      manualSortBy,
      disableSortBy,
      disableMultiSort,
      maxMultiSortColCount,
      sortTypes,
      autoResetSortBy,
      manuaxlroupBy,
      disableGroupBy,
      aggregations,
      manualExpandedKey,
      expandSubRows,
      autoResetExpanded,
      autoResetPage,
      paginateExpandedRows,
      manualPagination,
      pageCount: initialPageCount,
      manualRowSelectedKey,
      autoResetSelectedRows,
      getSubRows,
    },
    useColumnOrder,
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useRowSubComponent(!!rowSubComponent),
    useRowSelection(enableRowSelection, enableSingleRowSelection),
  );

  const selectedRows = preFilteredRows.filter(row => Object.keys(state.selectedRowIds).includes(row.id));

  useEffect(() => {
    onSelectedRowsChange(selectedRows);
  }, [Object.keys(state.selectedRowIds).length])

  const hiddenSelectedRowsMessage = () => {
    const selectedRowIds = Object.keys(state.selectedRowIds);
    const filteredRowIds = rows.map((filteredRow) => filteredRow.id);
    const selectedRowsHiddenByFilter = selectedRowIds.filter(hiddenRows).length;

    function hiddenRows(selectedRowId: string) {
      return !filteredRowIds.includes(selectedRowId);
    }

    function noColumnsAreGrouped() {
      return allColumns.every((column) => !column.isGrouped);
    }

    function noResults() {
      return page.length === 0;
    }

    function filterActive() {
      return state.filters.length;
    }

    if (selectedRowsHiddenByFilter && noColumnsAreGrouped()) {
      return (
        <Typography variant="caption" color="error">
          {`${pluralize(
            "row",
            selectedRowsHiddenByFilter,
            true
          )} hidden by your current filter`}
        </Typography>
      );
    }

    if (!filterActive() && noResults()) {
      return (
        <Typography variant="caption" color="error">
          No data
        </Typography>
      );
    }

    if (filterActive() && noResults()) {
      return (
        <Typography variant="caption" color="error">
          No data
        </Typography>
      );
    }

    return null;
  };

  const headerProps = (
    props: HeaderPropGetter<object> | undefined,
    { column }: any
  ) => {
    return getStyles(props, column.align);
  };

  const cellProps = (props: any, { cell }: any) =>
    getStyles(props, cell.column.align);

  const getStyles = (props: any, align: "left" | "right" | "center" = "left") => [
    props,
    {
      style: {
        justifyContent: alignmentParser(align),
        alignItems: "center",
        display: "flex",
      },
    },
  ];

  function alignmentParser(align: "left" | "right" | "center") {
    switch (true) {
      case align === "left":
        return "flex-start"
      case align === "right":
        return "flex-end"
      case align === "center":
        return "center"
    }
  }

  const clearAllFilters = () => {
    setAllFilters([])
  };

  const forceGoToPage = async (event: any, newPage: any) => {
    // gotoPage has the wrong type from react-table. It is awaitable so we cast it as such here.
    await (gotoPage as (
      updater: number | ((pageIndex: number) => number)
    ) => Promise<void>)(newPage);

    // pageChange can detect newPage as incorrectly non-existent on the first attempt so we trigger gotoPage again to ignore this mistake.
    if (pageChangeRejected()) {
      gotoPage(newPage);
    }
    function pageChangeRejected() {
      return state.pageIndex != newPage;
    }
  };

  if (invalidPageIndex()) {
    goToFirstPage();
  }

  function invalidPageIndex() {
    const pageCount = Math.trunc(rows.length / state.pageSize);
    return state.pageIndex > pageCount;
  }

  useEffect(goToFirstPage, [state.filters]);

  function goToFirstPage() {
    gotoPage(0);
  }

  const handleGlobalFilterChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => {
    goToFirstPage();
    setGlobalFilter(currentTarget.value);
  };

  const fillerCells = hasSecondPage()
    ? [...Array(state.pageSize - page.length).keys()]
    : [];

  function hasSecondPage() {
    return page?.length && state.pageIndex > 0;
  }

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const tableActionsCallback = useCallback(tableActions, [data])

  useEffect(() => {
    if(!id) return;
    const userVisibleColumns = JSON.parse(String(localStorage.getItem(id)))
    if(userVisibleColumns) {
      setHiddenColumns((hiddenColumns) => [...hiddenColumns, ...optionalColumns.filter(column => !userVisibleColumns.includes(column))])
    } else {
      setHiddenColumns(hiddenColumns)
    }
  },[])

  const onlyColumnsWithAdjustableVisibility = (
    column: ColumnInstance<object>
  ) => optionalColumns.includes(column.id);

  const selectableColumns = allColumns.filter(onlyColumnsWithAdjustableVisibility)
  const selectableColumnIds = selectableColumns.map((column) => column.id)

  useEffect(() => {
    if (!id) return;
    const columnOrder = JSON.parse(
      String(localStorage.getItem(`${id}-column-order`))
    );
    if (columnOrder) {
      const newColumns = selectableColumnIds.filter(columnId => !columnOrder.includes(columnId))
      if (newColumns.length) {
        localStorage.setItem(`${id}-column-order`, JSON.stringify([...columnOrder, ...newColumns]))
        columnOrder.push(...newColumns)
      }
      setColumnOrder(columnOrder);
    } else {
      setColumnOrder(selectableColumnIds)
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    const userFilters: Filters<any> | undefined = JSON.parse(
      String(localStorage.getItem(`filters-${id}`))
    );
    Array.isArray(userFilters) && setAllFilters(userFilters);
  }, []);

  const setAllRowsAsSelected = (set?: boolean) => {
    const previousFilters = state.filters
    clearAllFilters();
    toggleAllRowsSelected(set);
    setAllFilters(previousFilters)
  }

  const unselectRows = async () => {
    const previousFilters = state.filters
    await new Promise((resolve) => {
      setAllFilters([]);
      resolve("");
    });
    toggleAllRowsSelected(false)
    await new Promise((resolve) => {
      setAllFilters(previousFilters);
      resolve("");
    })
  }
  return (
    <Card sx={{ zIndex: "unset" }}>
      <TopActionGrid>
        <IconButton
          onClick={({ currentTarget }) => setPopoverAnchor(currentTarget)}
        >
          <OpenPopoverIcon />
        </IconButton>
        <TableActionPopover
          anchorEl={popoverAnchor}
          open={Boolean(popoverAnchor)}
          close={() => setPopoverAnchor(null)}
          clearAllFilters={clearAllFilters}
          allColumns={allColumns}
          filters={state.filters}
          optionalColumns={optionalColumns}
          hiddenColumns={hiddenColumns}
          setColumnOrder={setColumnOrder}
          setHiddenColumns={setHiddenColumns}
          visibleColumns={visibleColumns}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          tableId={id}
        />
        <TableTitle variant="h6">{tableTitle}</TableTitle>
        {hiddenSelectedRowsMessage()}
        <TableActions>
          {tableActionsCallback({
            selectedRows,
            toggleAllRowsSelected: setAllRowsAsSelected,
            filteredRows,
            currentFilters: state.filters
          })}
        </TableActions>
        {showGlobalFilter && (
          <GlobalFilterSearch
            placeholder="Search"
            onChange={handleGlobalFilterChange}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        )}
      </TopActionGrid>
      <TableContainer
        {...getTableProps()}
        sx={{ minHeight: 530, "::-webkit-scrollbar": { height: "16px" } }}
      >
        {isLoading && <TableLoading isLoading={isLoading} />}
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <HeaderGroup {...headerGroup.getHeaderGroupProps({})}>
              {headerGroup.headers.map((column, index) => (
                <ColumnHeader
                  key={index}
                  headerGroup={column}
                  gotoPage={gotoPage}
                  headerProps={headerProps}
                  sx={{
                    borderTopLeftRadius: index === 0 ? 8 : 0,
                    borderBottomLeftRadius: index === 0 ? 8 : 0,
                    borderTopRightRadius:
                      index === headerGroup.headers.length - 1 ? 8 : 0,
                    borderBottomRightRadius:
                      index === headerGroup.headers.length - 1 ? 8 : 0,
                  }}
                />
              ))}
            </HeaderGroup>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            const onClick = async (event: MouseEvent<HTMLDivElement>) => {
              if (selectRowOnRowClick){
                enableSingleRowSelection && await unselectRows();
                row.toggleRowSelected();
              } else {
                onRowClick && onRowClick(row, event)
              } 
            }

            return (
              <Fragment key={row.getRowProps().key}>
                <TableRow
                  {...row.getRowProps()}
                  onClick={onClick}
                  clickable={Boolean(onRowClick || selectRowOnRowClick)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps(cellProps)}>
                        <CellContent row={row} cell={cell} />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <RowExpansion
                  row={row}
                  rowSubComponent={rowSubComponent}
                  component={TableRow}
                />
              </Fragment>
            );
          })}
          {fillerCells.map((_, index) => {
            return (
              <Fragment key={index}>
                <TableRow>
                  <TableCell />
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={state.pageSize}
        page={state.pageIndex}
        onPageChange={forceGoToPage}
        rowsPerPageOptions={[5, 8, 10, 20, 50, 100]}
        onRowsPerPageChange={(e) => {
          localStorage.setItem("pageSize", String(e.target.value));
          setPageSize(Number(e.target.value));
        }}
      />
    </Card>
  );
};

export default useReactTable;
