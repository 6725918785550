import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import _ from "lodash";
import { useSelector } from 'react-redux';
import { initWorkOrderTask } from 'store/api/workOrder';
import { IStandardWorkOrder, WorkOrderStatus } from 'store/models/WorkOrder';
import { selectLoggedInUser, simpleGlobalMessage } from 'store/slices/systemSlice';
import UserData from 'store/models/UserData';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { toMMDDYYYY } from "utils/util";
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';

interface Props {
  enableDelete?: boolean;
  persistChanges?: boolean;
  enableAdd?: boolean;
}

const Tasks: React.FC<Props> = ({ enableDelete, persistChanges, enableAdd }) => {
  const { values, setFieldValue, submitForm, touched, errors } = useFormikContext<IStandardWorkOrder>();
  const [task, setTask] = useState(initWorkOrderTask())
  const taskInputRef = useRef<HTMLInputElement | null>(null)
  const currentUser = useSelector(selectLoggedInUser) as UserData;
  const dispatch = useAppDispatch();
  const isComplete = values.status === WorkOrderStatus.COMPLETE;
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const userCanEditTasks = userCanEditTasksFromView();
  
  function userCanEditTasksFromView (){
    return persistChanges ? checkMaintenancePermissions(ActionType.LIMITED_UPDATE) : true;
  } 

  const createNewTask = () => {

    const newTasksArray = _.cloneDeep(values.tasks);
    if (!task.description.length) {
      dispatch(simpleGlobalMessage('You need to give your task a description'));
      return;
    }
    setFieldValue("tasks", [...newTasksArray, task]);
    persistChanges && submitForm();
    resetUnsavedTask();
    taskInputRef.current?.focus()
  };

  function resetUnsavedTask(){
    setTask(initWorkOrderTask());
  }

  const deleteTask = (index: number) => {
    const newTasksArray = _.cloneDeep(values.tasks);
    newTasksArray.splice(index, 1);
    setFieldValue("tasks", newTasksArray);
    persistChanges && submitForm();
  };

  const completeTask = (taskIndex: number) => {
    const newTasksArray = _.cloneDeep(values.tasks)
    const undoCompletion = newTasksArray[taskIndex].complete
    newTasksArray[taskIndex] = {
      ...newTasksArray[taskIndex],
      complete: !undoCompletion,
      completedBy: undoCompletion ? null : currentUser._id,
      completedDate: undoCompletion ? null : toMMDDYYYY(new Date()),
    };
    setFieldValue("tasks", newTasksArray);
    persistChanges && submitForm();
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {enableAdd && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 2,
          }}
        >
          <TextField
            inputRef={taskInputRef}
            value={task?.description || ""}
            onChange={({ currentTarget }) => {
              setTask((previousValues) => ({
                ...previousValues,
                description: currentTarget.value,
              }));
            }}
            label="Task"
            fullWidth
            size="small"
            error={Boolean(touched.tasks && errors.tasks)}
            onKeyPress={(e) => e.key === "Enter" && createNewTask()}
            disabled={
              [WorkOrderStatus.CANCELLED, WorkOrderStatus.COMPLETE].includes(
                values.status
              ) || !userCanEditTasks
            }
          />
          <Button
            variant="outlined"
            onClick={createNewTask}
            disabled={isComplete || !userCanEditTasks}
          >
            Add
          </Button>
        </Box>
      )}
      <List>
         {values.tasks ? values.tasks.map((task, index) => (
          <ListItem key={`${task.description}-${index}`}>
            <ListItemText
              sx={{
                color: task.complete ? "text.secondary" : undefined,
                textDecoration: task.complete ? "line-through" : undefined,
                maxWidth: "300px",
              }}
            >
              {index + 1}. {task.description}
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                onClick={() => completeTask(index)}
                disabled={isComplete || !userCanEditTasks}
              >
                {task.complete ? "Undo" : "Complete"}
              </Button>
              {enableDelete && (
                <IconButton color="error" onClick={() => deleteTask(index)}>
                  <DeleteForever />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )) : ""}
      </List>
    </Box>
  );
};
export default Tasks;
