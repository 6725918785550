import { IconButton } from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import { Hooks, TableExpandedToggleProps } from "react-table";

const useRowSubComponent = (rowSubComponent: boolean) => {
  return (hooks: Hooks<object>) => {
    rowSubComponent &&
      hooks.visibleColumns.push((columns) => [
        {
          id: "expansion",
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <IconButton size="small" {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          ),
          Cell: ({ row }) => {
            const getToggleRowExpandedProps = row.getToggleRowExpandedProps as () => Partial<TableExpandedToggleProps> & {
              onClick: (e: React.MouseEvent) => void;
            };
            return (
              <IconButton
                size="small"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  getToggleRowExpandedProps().onClick(e);
                }}
              >
                {row.isExpanded ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            );
          },
          width: 2,
        },
        ...columns,
      ]);
  };
};
export default useRowSubComponent;
