import { FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { Contract, inactiveContractStatuses } from "store/models/Contract";
import { selectAssets } from "store/slices/assetSlice";
import { toMMDDYYYY } from "utils/util";
import { Asset } from "store/models/Asset";
import { assetOnActiveContractCanBeRemoved } from "features/contracts/contractUtils";
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridColDef,
  GridDeleteForeverIcon,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridToolbar,
  GridValueFormatterParams,
  GridValueSetterParams,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { fCurrency } from "utils/formatNumber";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { isValid } from "date-fns";
import Label from "components/label";


interface Props {
  contract: Contract;
  updateAssetDeliverables: (
    assets: AssetDeliverable[],
    setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
    setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
  ) => void;
  removeAsset: (
    asset: Asset,
    setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
    setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
  ) => void;
  isCreatingReservation: boolean;
  disableEditing: boolean;
  sx?: DataGridProProps["sx"];
}

function EditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  return <GridEditInputCell {...props} sx={{border: error ? "1px solid red" : "", height: "100%"}} />;
}

function renderEditInput(params: GridRenderEditCellParams) {
  return <EditInputCell {...params} />;
}


const EditableAssetsTable: FC<Props> = ({
  contract,
  sx,
  updateAssetDeliverables,
  removeAsset,
  isCreatingReservation,
  disableEditing,
}) => {
  const { assetDeliverables, contractType } = contract;
  const editingDisabled =
    disableEditing || inactiveContractStatuses.includes(contract?.status);
  const assets = useSelector(selectAssets);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const apiRef = useGridApiRef();
  const handleDateValueChange = (params: GridRenderEditCellParams, newValue: string | null) => {
    const {id, field} = params
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue || null,
    });
  };
  const disableRemoveAsset = (
    isSubmitting: boolean,
    numSelectedRows: number,
    asset: Asset
  ) => {
    if (isCreatingReservation) {
      return isSubmitting;
    } else {
      return (
        isSubmitting ||
        numSelectedRows > 1 ||
        editingDisabled ||
        !assetOnActiveContractCanBeRemoved(contract, asset)
      );
    }
  };

  const handleRemoveAsset = (assetId: string) => {
    setIsSubmitting(true);
    removeAsset(assets[assetId], setIsSubmitting);
  }

  const contractColumns: GridColDef<AssetDeliverable>[] = [
    {
      field: "serialNumber",
      headerName: "Serial #",
      width: 180,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.serialNumber;
      },
    },
    {
      field: "assetNumber",
      headerName: "Asset #",
      width: 180,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.assetNumber;
      },
    },
    {
      field: "category",
      headerName: "Category",
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.category;
      },
      width: 120,
    },
    {
      field: "subcategory",
      headerName: "Subcategory",
      width: 120,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.category;
      },
    },
    {
      field: "sizeCode",
      headerName: "Size Code",
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.sizeCode;
      },
      width: 180,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.category;
      },
      width: 180,
    },
    {
      field: "contractComplexId",
      headerName: "Contract Complex",
      editable: true,
      type: "text",
      width: 180,
      renderEditCell: renderEditInput,
    },
    {
      field: "complex",
      headerName: "Asset Complex",
      width: 180,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.complex;
      },
    },
    {
      field: "saleAmount",
      headerName: "Sale Amount",
      width: 180,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = false;
        if (params.props.value < 0) {
          hasError = true;
        }
        if (isNaN(params.props.value)) {
          hasError = true;
        }
        return { ...params.props, error: hasError };
      },
      valueGetter: (params) => {
        return params.row.saleAmount.amount;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return fCurrency(params.value);
      },
      valueSetter: (params: GridValueSetterParams<AssetDeliverable>) => {
        const { value } = params;
        const newRentalRate = {
          amount: Number(value),
          currency: params.row.saleAmount.currency,
        };
        return { ...params.row, rentalRate: newRentalRate };
      },
      renderEditCell: renderEditInput,
    },
    {
      field: "rentalRate",
      headerName: "Rental Rate",
      width: 180,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = false;
        if (params.props.value < 0) {
          hasError = true;
        }
        if (isNaN(params.props.value)) {
          hasError = true;
        }
        return { ...params.props, error: hasError };
      },
      valueGetter: (params) => {
        return params.row.rentalRate.amount;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return fCurrency(params.value);
      },
      valueSetter: (params: GridValueSetterParams<AssetDeliverable>) => {
        const { value } = params;
        const newRentalRate = {
          amount: Number(value),
          currency: params.row.rentalRate.currency,
        };
        return { ...params.row, rentalRate: newRentalRate };
      },
      renderEditCell: renderEditInput,
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      width: 180,
      editable: true,
      type: "date",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = false;
        if (!params.props.value) {
          hasError = true;
        }
        return { ...params.props, error: hasError };
      },
      valueGetter: (params) => {
        return new Date(params.row.deliveryDate);
      },
      renderEditCell(params) {
        return (
          <DatePicker
            value={isValid(params.value) ? params.value : null}
            {...params}
            onChange={(value) => handleDateValueChange(params, value)}
            renderInput={(props) => <TextField {...props} />}
          />
        );
      },
    },
    {
      field: "customBillingStart",
      headerName: "Billing Start",
      width: 180,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        const { customBillingStart } = params.row;
        return customBillingStart ? new Date(customBillingStart) : null;
      },
      renderEditCell(params) {
        return (
          <DatePicker
            value={isValid(params.value) ? params.value : null}
            {...params}
            onChange={(value) => handleDateValueChange(params, value)}
            renderInput={(props) => <TextField {...props} />}
          />
        );
      },
    },
    {
      field: "customBillingEnd",
      headerName: "Billing End",
      width: 160,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        const { customBillingEnd } = params.row;
        return customBillingEnd ? new Date(customBillingEnd) : null;
      },
      renderEditCell(params) {
        return (
          <DatePicker
            value={isValid(params.value) ? params.value : null}
            {...params}
            onChange={(value) => handleDateValueChange(params, value)}
            renderInput={(props) => <TextField {...props} />}
          />
        );
      },
    },
    {
      field: "isActiveOnContract",
      headerName: "Active",
      headerAlign: "center",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <Label variant="soft" color={params.row.isActiveOnContract ? 'success' : 'default'}>
          {params.row.isActiveOnContract ? 'Active' : 'Inactive'}
        </Label>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 180,
      getActions: (params) => {
        const apiRef = useGridApiContext();
        return [
          <GridActionsCellItem
            icon={<GridDeleteForeverIcon />}
            color="error"
            onClick={() => handleRemoveAsset(params.row.asset)}
            label="Delete"
            disabled={disableRemoveAsset(
              isSubmitting,
              apiRef.current.getSelectedRows().size,
              assets[params.row.asset]
            )}
          />,
        ];
      },
    },
  ];

  const reservationColumns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Serial #",
      flex: 1,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.serialNumber;
      },
    },
    {
      field: "assetNumber",
      headerName: "Asset #",
      flex: 1,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.assetNumber;
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.category;
      },
    },
    {
      field: "subcategory",
      headerName: "Subcategory",
      flex: 1,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.category;
      },
    },
    {
      field: "sizeCode",
      headerName: "Size Code",
      flex: 1,
      valueGetter: (params) => {
        const asset = assets[params.row.asset];
        return asset.sizeCode;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => {
        const apiRef = useGridApiContext();
        return [
          <GridActionsCellItem
            icon={<GridDeleteForeverIcon />}
            color="error"
            onClick={() => removeAsset(assets[params.row.asset])}
            label="Delete"
            disabled={disableRemoveAsset(
              isSubmitting,
              apiRef.current.getSelectedRows().size,
              assets[params.row.asset]
            )}
          />,
        ];
      },
    },]

  const handleRowEditCommit = async (
    newRow: AssetDeliverable,
    oldRow: AssetDeliverable
  ) => {

    const valueChanges = Object.keys(newRow)
      .filter(
        (key) =>
          !_.isEqual(
            oldRow[key as keyof AssetDeliverable],
            newRow[key as keyof AssetDeliverable]
          )
      )
      .reduce(
        (acc: Partial<AssetDeliverable>, key) => ({
          ...acc,
          [key]: convertToMMDDYYIfDate(newRow[key as keyof AssetDeliverable]),
        }),
        {}
      );
    if (_.isEmpty(valueChanges)) return oldRow;

    const deliverableUpdates = assetDeliverables
      .filter((deliverable) => selectedRows.length ? selectedRows.includes(deliverable.asset) : deliverable.asset === newRow.asset)
      .map((deliverable) => ({ ...deliverable, ...valueChanges }));

    function convertToMMDDYYIfDate(value: any) {
      return value instanceof Date ? toMMDDYYYY(value) : value;

    }

    setIsSubmitting(true);
    updateAssetDeliverables(deliverableUpdates, setIsSubmitting);
    return newRow;
  };


  return (
    <DataGridPro
      apiRef={apiRef}
      getRowId={(row) => row.asset}
      onRowSelectionModelChange={(ids) => {
        setSelectedRows(ids.map(id => String(id)));
      }}
      isCellEditable={(params) => {
        return selectedRows.length ? selectedRows.includes(String(params.id)) : true 
      }
      }
      disableRowSelectionOnClick
      rows={assetDeliverables}
      columns={isCreatingReservation ? reservationColumns : contractColumns}
      checkboxSelection
      processRowUpdate={handleRowEditCommit}
      pageSizeOptions={[5, 10, 20]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        columns: {
          columnVisibilityModel: {
            saleAmount: contractType === "Sale",
            rentalRate: contractType === "Rental",
            complex: assetDeliverables.some((deliverable) =>
              Boolean(assets[deliverable.asset].complex)
            ),
            contractComplexId: false
          },
        },
      }}
      slots={{
        toolbar: GridToolbar,
      }}
      slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      loading={isSubmitting}
    />
  );
};

export default EditableAssetsTable;
